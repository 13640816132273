export const SEATS = {
  get: (sessionId: string | string[], partnershipName = 'home') => {
    return `/assentos/${sessionId}/${partnershipName}`
  }
}

export const TICKETS = {
  get: (sessionId: string | string[], partnershipName = 'home') => {
    return `/ingressos/${sessionId}/${partnershipName}`
  }
}

export const PRODUCTS = {
  get: (sessionId: string | string[], partnershipName = 'home') => {
    return `/produtos/${sessionId}/${partnershipName}`
  }
}

export const LOGIN = {
  get: (sessionId: string | string[], partnershipName = 'home') => {
    return `/login/${sessionId}/${partnershipName}`
  }
}

export const PAYMENTS = {
  get: (sessionId: string | string[], partnershipName = 'home') => {
    return `/pagamentos/${sessionId}/${partnershipName}`
  }
}

export const CONFIRMED = {
  get: (sessionId: string | string[], partnershipName = 'home') => {
    return `/concluido/${sessionId}/${partnershipName}`
  }
}

export const WAIT_PAYMENT = {
  get: () => {
    return `/esperaPagamento`
  }
}
