const colors = {
  primary: '#E4650E',
  primary2: '#3155E8',
  secondary: '#3478C1',
  tertiary: '#9560A4',
  DodgerBlue: '#98AAEC',
  SafetyOrange: '#FFD633',
  FreeSpeech: '#00CC00',
  Scarlet: '#FF3300',
  OrangePeel: '#FF9900',
  DeepLemon: '#FBC115',
  Turquoise: '##00A651',
  Bittersweet: '#FF6666',
  Cioccolato: '#65320D',
  CaribbeanGreen: '#00CC99',
  buttonGrandientPrimary: '#FFCE00',
  buttonGrandientSecondary: '#FF8800',
  buttonBomboniereBorder: '#617694',
  ageRating: {
    Green: '#00A651',
    BlueCola: '#0095DA',
    DeepLemon: '#FBC115',
    PrincetonOrange: '#F58220',
    Red: '#EC1D25',
    Black: '#000',
    Default: '#666'
  },
  printColors: {
    SelectiveYellow: '#FBBA00',
    vividOrange: '#F39200',
    SafetyOrange: '#E4650E ',
    Pearl: '#A34A26',
    CuriousBlue: '#3478C1',
    Cobalt: '#3A73B3',
    VividViolet: '#9560A4',
    Amaranth: '#ED6C77'
  },
  NeutralColors: {
    White: '#FFFFFF',
    VeryLightGray: '#F2F2F2',
    VeryLightGray2: '#F0F0F0',
    LightGray3: '#8899AA',
    Gray80: '#ADB6C2',
    Gray60: '#999999',
    Gray40: '#666666',
    StrokeTabs: '#343C46',
    NightRider: '#041218',
    NightRider90: 'rgba(51,51,51,0.9)',
    Nero: '#252525',
    DarkGray: '#21262D',
    DarkGray80: 'rgba(25,25,25,0.8)'
  }
}
export default colors
