import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useReducer
} from 'react'
import { translations } from '@shared/utils/adyen/translations'
import AdyenCheckout from '@adyen/adyen-web'
import '@adyen/adyen-web/dist/adyen.css'
import { cardValuesReducer, initialState } from './reducer'
import { IPaymentToken } from './interface'

const AdyenCheckoutContext = createContext(null)

const ADYENCONFIG = {
  ADYEN_LOCALE: process.env.ADYEN_LOCALE,
  ADYEN_ENVIROMENT: process.env.ADYEN_ENVIROMENT,
  ADYEN_CLIENT_KEY: process.env.ADYEN_CLIENT_KEY
}

export const AdyenCheckoutProvider = ({ children }) => {
  const [checkoutInstance, setCheckoutInstance] = useState(null)
  const [fingerprint, setFingerPrint] = useState(null)
  const [fingerprintMyToken, setFingerprintMyToken] = useState(null)
  const [validFieldsCard, setValidFieldsCard] = useState(false)
  const [cardValue, dispatch] = useReducer(cardValuesReducer, initialState)
  const [paymentToken, setPaymentToken] = useState<IPaymentToken>()
  const [timePaymentAsync, SetTimePaymentAsync] = useState([])
  const onChange = (state) => {
    setValidFieldsCard(state.isValid)
    if (state.isValid) {
      setPaymentToken({
        paymentMethod: state.data.paymentMethod,
        browserInfo: state?.data?.browserInfo,
        holderName: state?.data?.paymentMethod?.holderName,
        encryptedCardNumber:
          state.data.encryptedCardNumber || state.data.paymentMethod.cardNumber,
        encryptedExpiryMonth:
          state.data.encryptedExpiryMonth ||
          state.data.paymentMethod.encryptedExpiryMonth,
        encryptedSecurityCode:
          state.data.encryptedSecurityCode ||
          state.data.paymentMethod.encryptedSecurityCode,
        storePaymentMethod: state.data.storePaymentMethod
      })
    }
  }

  const handleOnAdditionalDetails = (state) => {
    setFingerPrint(state?.data?.details?.threeDSResult)
    setFingerprintMyToken(state?.data?.details?.threeDSResult)
  }

  useEffect(() => {
    const createCheckoutInstance = async () => {
      const configuration = {
        translations: translations,
        locale: ADYENCONFIG.ADYEN_LOCALE,
        environment: ADYENCONFIG.ADYEN_ENVIROMENT,
        clientKey: ADYENCONFIG.ADYEN_CLIENT_KEY,
        onChange: onChange,
        onAdditionalDetails: handleOnAdditionalDetails,
        hasHolderName: true,
        showBrandIcon: true,
        holderNameRequired: true
      }

      const checkout = await AdyenCheckout(configuration)
      setCheckoutInstance(checkout)
    }
    createCheckoutInstance()
  }, [])

  const generateFingerprint = (data) => {
    setFingerPrint(data)
  }

  const resetState = () => {
    setFingerPrint(null)
    setFingerprintMyToken(null)
    setPaymentToken(null)
  }

  const generateFingerprintToken = (data) => {
    setFingerprintMyToken(data)
  }

  return (
    <AdyenCheckoutContext.Provider
      value={{
        adyenInstance: checkoutInstance,
        generateFingerprint: generateFingerprint,
        generateFingerprintToken: generateFingerprintToken,
        fingerprint: fingerprint,
        fingerprintMyToken: fingerprintMyToken,
        cardValue: cardValue,
        timePaymentAsync: timePaymentAsync,
        dispatch: dispatch,
        setPaymentToken,
        validFieldsCard,
        setValidFieldsCard,
        paymentToken,
        SetTimePaymentAsync,
        resetState
      }}
    >
      {children}
    </AdyenCheckoutContext.Provider>
  )
}

export const useAdyenCheckoutContext = () => {
  const context = useContext(AdyenCheckoutContext)

  if (!context) {
    throw new Error(
      'useAdyenCheckoutContext must be used within AdyenCheckoutProvider'
    )
  }

  const {
    adyenInstance,
    fingerprint,
    fingerprintMyToken,
    paymentToken,
    cardValue,
    resetState,
    validFieldsCard,
    timePaymentAsync
  } = context

  const generateFingerprint = (data) => {
    context.generateFingerprint(data)
  }

  const generateFingerprintToken = (data) => {
    context.generateFingerprintToken(data)
  }

  const sendDispatch = (data) => {
    context.dispatch(data)
  }

  const SetTimePaymentAsync = (data) => {
    context.SetTimePaymentAsync(data)
  }

  const setValidFieldsCard = (data) => {
    context.setValidFieldsCard(data)
  }

  const setPaymentToken = (data) => {
    context.setPaymentToken(data)
  }

  return {
    adyenInstance,
    fingerprint,
    fingerprintMyToken,
    generateFingerprint,
    generateFingerprintToken,
    dispatch: sendDispatch,
    setPaymentToken,
    paymentToken,
    cardValue,
    resetState,
    setValidFieldsCard,
    SetTimePaymentAsync,
    timePaymentAsync,
    validFieldsCard
  }
}

export default AdyenCheckoutContext
