import React, { createContext, useRef } from 'react'

export const ReCaptchaContext = createContext(null)

export const ReCaptchaProvider = ({ children }) => {
  const recaptchaRefV2 = useRef(null)

  return (
    <ReCaptchaContext.Provider value={{ recaptchaRefV2 }}>
      {children}
    </ReCaptchaContext.Provider>
  )
}
