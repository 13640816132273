import { cpfMask, validarCPF } from './CPF'
export const getIconNameByTicketName = (name: string): string => {
  if (name === 'Meia') {
    return 'brands/ticket-half'
  } else if (name === 'Meia Telecine') {
    return 'brands/telecine'
  }
  return 'brands/ticket-full'
}

export const removeMaskCpf = (cpf: string): string => {
  if (validarCPF(cpf)) {
    return cpf.replace(/\D/g, '')
  }
  return cpf
}

export const generateFieldsByQuantity = (quantity, field, fieldsInVector) => {
  if (field?.id) delete field?.id
  const fields = []

  if (fieldsInVector === null) {
    fields.push({
      id: quantity,
      ...field,
      document: '',
      activeError: false,
      promotion: { document: '', id: quantity, type: '' },
      errorMessage: '',
      typeStopInteraction: 'error',
      valid: getValidFunctionByDescriptionField(field)
    })
  } else {
    fields.push(...fieldsInVector.fields, {
      id: quantity,
      ...field,
      document: '',
      activeError: false,
      promotion: { document: '', id: quantity, type: '' },
      errorMessage: '',
      typeStopInteraction: 'error',
      valid: getValidFunctionByDescriptionField(field)
    })
  }
  return fields
}

export const generateFieldsByQuantityFidelity = (quantity, field) => {
  if (field?.id) delete field?.id
  const fields = []

  for (let i = 1; i <= quantity; i++) {
    fields.push({
      id: i,
      description: 'CPF',
      size: 22,
      type: 'text',
      document: '',
      activeError: false,
      promotion: {
        document: '',
        type: '',
        valid: (inputValue) => {
          return validarCPF(inputValue)
        }
      },
      errorMessage: '',
      typeStopInteraction: 'neverInteracted'
    })
  }

  return fields
}
export const getValidFunctionByDescriptionField = (field) => {
  if (field?.description === 'Nº do documento de meia') {
    return (inputValue) => {
      return inputValue.length > 0
    }
  } else if (field?.description === 'CPF') {
    return (inputValue) => {
      return validarCPF(inputValue)
    }
  } else {
    return (inputValue) => {
      return inputValue.length > 0
    }
  }
}
export function getTotalQuantityTickets(ArrayOfObjects) {
  let totalQuantity = 0

  for (let i = 0; i < ArrayOfObjects.length; i++) {
    totalQuantity += ArrayOfObjects[i].quantity
  }

  return totalQuantity
}

export const handleCpfEmail = (value: string) => {
  const email = /\S+@\S+\.\S+/.test(value)
  const cpf = cpfMask(value, null) && !email
  if (cpf) {
    return {
      label: 'CPF',
      value: cpfMask(value, null),
      mask: '999.999.999-99'
    }
  }
  if (email) {
    return {
      label: 'E-mail',
      value: value,
      mask: ''
    }
  }
}
export const maskCpfCnpjInput = (data) => {
  if (data.length > 11) {
    let cnpj = `${data.substr(0, 2)}.${data.substr(2, 3)}.${data.substr(5, 3)}/`
    if (data.length > 12) {
      cnpj += `${data.substr(8, 4)}-${data.substr(12, 2)}`
    } else {
      cnpj += data.substr(8)
    }
    data = cnpj
  } else {
    let cpf = ''
    const parts = Math.ceil(data.length / 3)
    for (let i = 0; i < parts; i++) {
      if (i === 3) {
        cpf += `-${data.substr(i * 3)}`
        break
      }
      cpf += `${i !== 0 ? '.' : ''}${data.substr(i * 3, 3)}`
    }
    data = cpf
  }
  return data
}

export const getCurrentDomainCookie = () => {
  const isClient = typeof document !== 'undefined'
  if (isClient)
    return document.location.hostname === 'localhost'
      ? 'localhost'
      : process.env.cookieDomain
}
export const getFormatedDateUTC = (dateUTC: string) => {
  const day = new Date(dateUTC).getUTCDate()
  const month = new Date(dateUTC).getUTCMonth() + 1
  const year = new Date(dateUTC).getUTCFullYear()
  const formattedMonth = month < 10 ? `0${month}` : `${month}`
  const formattedDay = day < 10 ? `0${day}` : `${day}`

  return `${formattedDay}/${formattedMonth}/${year}`
}
export const verifyIsSafariNavigator = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export const filterPropertiesUrl = (queryParams) => {
  const cleanedParams = {}

  for (const key in queryParams) {
    if (Object.prototype.hasOwnProperty.call(queryParams, key)) {
      let value =
        typeof queryParams[key] === 'string'
          ? queryParams[key].trim()
          : queryParams[key]

      if (typeof value === 'string') {
        value = encodeURIComponent(value)
      }

      if (value !== undefined && value !== null && value !== '') {
        cleanedParams[key] = value
      }
    }
  }

  return cleanedParams
}
