import { POWEREDBY_DATA } from '@Constants/sessionStorage'
import { TResponseData } from '@shared/interfaces/PoweredBy'

import { IPoweredByResponse } from '@shared/interfaces/PoweredBy'

export function setThemeSessionStorage(json: object) {
  if (typeof window !== 'undefined') {
    sessionStorage.setItem(POWEREDBY_DATA, JSON.stringify(json))
  }
}
export function getThemeSessionStorage(): IPoweredByResponse {
  if (typeof window !== 'undefined') {
    return JSON.parse(sessionStorage.getItem(POWEREDBY_DATA) || '{}')
  }
  return {} as IPoweredByResponse
}
export function deleteThemeSessionStorage() {
  if (typeof window !== 'undefined') {
    sessionStorage.removeItem(POWEREDBY_DATA)
  }
}
export function isCheckoutPersonalized() {
  if (typeof window !== 'undefined') {
    const theme = JSON.parse(sessionStorage.getItem(POWEREDBY_DATA) || '{}')
    if (theme && theme?.styledCheckout) {
      return true
    }
    return false
  }
}
const getLogoPb = (isMobile: boolean, theme: TResponseData) => {
  const LogoDark = '/static/images/logo-desktop-black.svg'
  const LogoDarkMobile = '/static/images/logo-mobile-black.svg'
  const LogoLight = '/static/images/logo2.svg'
  const LogoLightMobile = '/static/images/logo-branco-mobile.svg'
  const LogoAzul = '/static/images/logo-azul-desktop.svg'
  const LogoAzulMobile = '/static/images/logo-azul-mobile.svg'
  if (theme?.data.isPoweredBy) {
    if (isMobile) {
      if (theme?.data.darkHeader) return LogoLightMobile
      else if (theme?.data.headerColor) return LogoDarkMobile
      return LogoAzulMobile
    } else {
      if (theme?.data.darkHeader) return LogoLight
      else if (theme?.data.headerColor) return LogoDark
      return LogoAzul
    }
  }
  return LogoLightMobile
}
export function getThemeLogo(isMobile: boolean, theme: TResponseData) {
  const LogoOriginal =
    'https://ingresso-a.akamaihd.net/ingresso/checkout/images/logo/logo.svg'
  const Logo2Original =
    'https://ingresso-a.akamaihd.net/ingresso/checkout/images/logo/logo2.svg'
  const logoPb = getLogoPb(isMobile, theme)
  const width2Original = '150px'
  const widthOriginal = '30px'
  const widthPB = '100px'
  const widthPBMobile = '20px'
  const isPoweredBy = theme?.data.isPoweredBy
  const logo = theme?.data.logo || Logo2Original
  const logoMobile = theme?.data.logoMobile || LogoOriginal
  const logoWidth = theme?.data.width || width2Original
  const logoMobileWidth = theme?.data.widthMobile || widthOriginal
  return {
    logo,
    logoMobile,
    logoWidth,
    logoMobileWidth,
    LogoOriginal,
    Logo2Original,
    width2Original,
    widthOriginal,
    isPoweredBy,
    logoPb,
    widthPB,
    widthPBMobile
  }
}
