import React, { useCallback, useEffect, useState } from 'react'
import { GlobalContext, GlobalStateProvider } from '@shared/context/Global'
import { GlobalStyle } from '@Styles/global'
import { CookiesProvider } from 'react-cookie'
import { ThemeProvider } from 'styled-components'
import PageTransition from '@Components/PageTransition'
import { ReCaptchaProvider } from '@shared/context/Recaptcha/RecaptchaContext'

import '../../public/static/fonts/fonts.css'
import theme from '@Styles/theme'
import { AdyenCheckoutProvider } from '@shared/context/Adyen/AdyenContext'
import { LoginProvider } from '@shared/context/Login/LoginContext'
// cache
import { QueryClientProvider, QueryClient } from 'react-query'

import { clarity } from 'react-microsoft-clarity'

const queryClient = new QueryClient()

import Head from 'next/head'
import Script from 'next/script'
import { useContextSelector } from 'use-context-selector'
import { TResponseData } from '@shared/interfaces/PoweredBy'
import { useRouter } from 'next/router'
import {
  deleteThemeSessionStorage,
  setThemeSessionStorage,
  getThemeSessionStorage
} from '@shared/utils/theme'
import { IPoweredByResponse } from '@shared/interfaces/PoweredBy'

function MyApp({ Component, pageProps, router }) {
  const { query } = useRouter()
  const sessionId = query.sessionId ? query.sessionId : router.query.params?.[0]

  const uolAdsLoaded = useContextSelector(
    GlobalContext,
    (context) => context?.setUolAds
  )
  const [poweredByTheme, setPoweredByTheme] = useState<IPoweredByResponse>(
    () => {
      return getThemeSessionStorage()
    }
  )
  const getJsonPoweredBy = async (partnership: string) => {
    try {
      const response = await fetch(
        process.env.baseUrlApiTicketing +
          '/partnerships/' +
          partnership +
          '/sessions/' +
          sessionId
      )
      const json: IPoweredByResponse = await response.json()

      if (json.ncGlobalPrimaryColor) {
        setThemeSessionStorage(json)
      }
      sessionStorage.setItem(
        'partnerdatashare',
        JSON.stringify(json.partnerDataShare)
      )

      setPoweredByTheme(json)
    } catch (e) {
      setPoweredByTheme({} as IPoweredByResponse)
    }
  }
  const isCheckoutPersonalized = () => {
    return poweredByTheme?.styledCheckout !== null
  }
  useEffect(() => {
    clarity.init(process.env.CLARITY_ID)
    const isPoweredBy = query?.partnership
    if (
      isPoweredBy &&
      (!poweredByTheme?.name || poweredByTheme.name !== isPoweredBy.toString())
    ) {
      getJsonPoweredBy(isPoweredBy?.toString())
    } else {
      if (!isPoweredBy) {
        sessionStorage.setItem('partnerdatashare', JSON.stringify(false))
        setPoweredByTheme({} as IPoweredByResponse)
        deleteThemeSessionStorage()
      }
    }
  }, [])

  const getTheme = useCallback(() => {
    const getColorHeader = poweredByTheme.ncDarkHeader
      ? '#fff'
      : poweredByTheme.ncHeaderColor
      ? '#000'
      : poweredByTheme.ncGlobalBackgroundColor

    // cor terciaria no campo poweredByTheme.ncHeaderColor
    let themeCheckout = {}
    if (isCheckoutPersonalized() && poweredByTheme?.styledCheckout) {
      themeCheckout = {
        data: {
          isCheckoutPersonalized: true,
          isPoweredBy: false,
          url: poweredByTheme.ncUrl,
          logo: poweredByTheme.ncLogo,
          width: poweredByTheme.ncLogoWidth,
          logoMobile: poweredByTheme.ncLogoMobile,
          widthMobile: poweredByTheme.ncLogoMobileWidth,
          darkHeader: poweredByTheme.ncDarkHeader,
          headerColor: poweredByTheme.ncHeaderColor
        },
        global: {
          ...theme.global,
          primary: {
            bgColor: poweredByTheme.ncGlobalPrimaryColor,
            color: poweredByTheme.ncGlobalPrimaryColor
          },
          secondary: {
            color: poweredByTheme.ncGlobalSecondaryColor
          },
          tertiary: {
            color: poweredByTheme.ncHeaderColor
          },
          link: {
            color: poweredByTheme.ncGlobalSecondaryColor
          },
          text: {
            color: poweredByTheme.ncGlobalTextColor
          },
          background: {
            color: poweredByTheme.ncGlobalContainerColor
          },
          placemark: {
            color: poweredByTheme.ncGlobalSecondaryColor
          }
        },
        images: {
          bg: poweredByTheme.styledCheckout.backgroundImageUrl,
          bgMobile: poweredByTheme.styledCheckout.backgroundImageMobileUrl,
          footer: poweredByTheme.styledCheckout.backgroundFooterImageUrl
        },
        header: {
          bgColor:
            poweredByTheme.ncHeaderColor || poweredByTheme.ncGlobalPrimaryColor,
          color: getColorHeader,
          icons: {
            color: getColorHeader
          },
          text: { color: getColorHeader }
        },
        login: {
          bgColor: poweredByTheme.ncGlobalContainerColor
        },
        tabs: {
          ...theme.tabs,
          bgActive: poweredByTheme.ncGlobalPrimaryColor,
          colorActive: poweredByTheme.ncGlobalBackgroundColor,
          availableColor: poweredByTheme.ncHeaderColor,
          disabledColor: '#ccc',
          textColor: poweredByTheme.ncHeaderColor // cor terciaria
        },
        sessionContainerMovie: {
          bgColor: poweredByTheme.ncGlobalContainerColor,
          color: poweredByTheme.ncGlobalSecondaryColor,
          hourSession: {
            color: poweredByTheme.ncHeaderColor
          }
        },
        seats: {
          ...theme.seats,
          title: {
            color: poweredByTheme.ncGlobalTextColor
          },
          zoom: {
            bgColor: poweredByTheme.ncGlobalTextColor,
            color: poweredByTheme.ncGlobalTextColor
          },
          screenSeatMap: {
            color: poweredByTheme.ncGlobalTextColor,
            bgColor: poweredByTheme.ncGlobalContainerColor
          },
          screen: {
            color: poweredByTheme.ncHeaderColor,
            bgColor: poweredByTheme.ncGlobalTextColor,
            border: poweredByTheme.ncGlobalTextColor
          },
          modal: {
            ...theme.modal,
            bgContentColor: poweredByTheme.ncGlobalContainerColor,
            bgImage: poweredByTheme.ncDarkHeader
              ? '/static/images/backgrounds/dark.jpg'
              : '/static/images/backgrounds/light.jpg',
            titleColor: poweredByTheme.ncGlobalTextColor,
            descriptionColor: poweredByTheme.ncGlobalTextColor
          },
          ticketBox: {
            selectedQtd: {
              bgColor: poweredByTheme.ncGlobalSecondaryColor,
              color: poweredByTheme.ncGlobalBackgroundColor,
              iconColor: poweredByTheme.ncGlobalBackgroundColor
            },
            selected: {
              color: poweredByTheme.ncHeaderColor,
              borderColor: poweredByTheme.ncGlobalPrimaryColor,
              bgColor: poweredByTheme.ncGlobalPrimaryColor
            },
            columnLabelColor: poweredByTheme.ncHeaderColor
          }
        },
        subtitleSeats: {
          title: {
            color: poweredByTheme.ncHeaderColor
          },
          bgColor: 'none',
          color: poweredByTheme.ncHeaderColor
        },
        warningSession: {
          bgColor: poweredByTheme.ncGlobalBackgroundColor,
          color: poweredByTheme.ncHeaderColor,
          icon: {
            color: poweredByTheme.ncHeaderColor,
            bgColor: poweredByTheme.ncGlobalBackgroundColor
          }
        },
        summary: {
          color: {
            primary: poweredByTheme.ncHeaderColor
          },
          header: {
            color: poweredByTheme.ncHeaderColor
          },
          bgColor: poweredByTheme.ncGlobalContainerColor,
          icons: {
            secondary: poweredByTheme.ncHeaderColor
          },
          tab: {
            color: {
              disabled: poweredByTheme.ncGlobalTextColor
            }
          },
          total: {
            color: poweredByTheme.ncHeaderColor
          }
        },
        footer: {
          boxShadow: poweredByTheme.ncGlobalTextColor,
          bgColor: poweredByTheme.ncGlobalContainerColor,
          color: poweredByTheme.ncGlobalTextColor,
          quantity: {
            bgColor: poweredByTheme.ncGlobalPrimaryColor,
            color: poweredByTheme.ncHeaderColor
          },
          value: {
            color: poweredByTheme.ncHeaderColor
          },
          ticketList: {
            bgColor: poweredByTheme.ncGlobalTextColor
          }
        },
        button: {
          primary: {
            bgColor: poweredByTheme.ncGlobalPrimaryColor,
            color: poweredByTheme.ncHeaderColor
          },
          buttonBomboniere: {
            bgColor: poweredByTheme.ncGlobalPrimaryColor,
            color: poweredByTheme.ncGlobalBackgroundColor,
            textActiveColor: poweredByTheme.ncGlobalBackgroundColor,
            backgroundActiveColor: poweredByTheme.ncGlobalPrimaryColor
          },
          gradient: {
            bgColor: poweredByTheme.ncGlobalPrimaryColor,
            loginBgColor: poweredByTheme.ncGlobalPrimaryColor,
            loginColor: poweredByTheme.ncGlobalBackgroundColor,
            color: poweredByTheme.ncGlobalTextColor
          },
          disabled: {
            bgColor: poweredByTheme.ncGlobalTextColor,
            color: poweredByTheme.ncGlobalTextColor
          }
        },
        ticketBox: {
          selectedQtd: {
            bgColor: poweredByTheme.ncGlobalSecondaryColor,
            color: poweredByTheme.ncGlobalPrimaryColor,
            disabled: '#666'
          },
          bgColor: poweredByTheme.ncGlobalContainerColor,
          nameColor: poweredByTheme.ncHeaderColor,
          oldPrice: poweredByTheme.ncHeaderColor,
          currentPrice: poweredByTheme.ncHeaderColor,
          serviceChargeColor: poweredByTheme.ncHeaderColor,
          notificationColor: poweredByTheme.ncGlobalSecondaryColor,
          promotionNotificationColor: poweredByTheme.ncGlobalPrimaryColor,
          disabledColor: poweredByTheme.ncHeaderColor,
          count: {
            color: poweredByTheme.ncHeaderColor
          },
          operators: {
            color: poweredByTheme.ncHeaderColor
          }
        }
      }
    } else if (poweredByTheme?.ncGlobalPrimaryColor) {
      themeCheckout = {
        data: {
          isCheckoutPersonalized: false,
          isPoweredBy: true,
          url: poweredByTheme.ncUrl,
          logo: poweredByTheme.ncLogo,
          width: poweredByTheme.ncLogoWidth,
          logoMobile: poweredByTheme.ncLogoMobile,
          widthMobile: poweredByTheme.ncLogoMobileWidth,
          darkHeader: poweredByTheme.ncDarkHeader,
          headerColor: poweredByTheme.ncHeaderColor
        },
        global: {
          ...theme.global,
          primary: {
            bgColor: poweredByTheme.ncGlobalPrimaryColor,
            color: poweredByTheme.ncGlobalPrimaryColor
          },
          secondary: {
            color: poweredByTheme.ncGlobalSecondaryColor
          },
          tertiary: {
            color: ''
          },
          link: {
            color: poweredByTheme.ncGlobalLinkColor
          },
          text: {
            color: poweredByTheme.ncGlobalTextColor
          },
          background: {
            color: poweredByTheme.ncGlobalBackgroundColor
          },
          placemark: {
            color: poweredByTheme.ncGlobalSecondaryColor
          }
        },
        login: {
          bgColor: poweredByTheme.ncGlobalContainerColor
        },
        images: {
          bg: '',
          bgMobile: '',
          footer: ''
        },
        header: {
          bgColor:
            poweredByTheme.ncHeaderColor || poweredByTheme.ncGlobalPrimaryColor,
          color: getColorHeader,
          icons: {
            color: getColorHeader
          },
          text: { color: getColorHeader }
        },
        tabs: {
          ...theme.tabs,
          bgActive: poweredByTheme.ncGlobalSecondaryColor,
          colorActive: poweredByTheme.ncGlobalBackgroundColor,
          availableColor: poweredByTheme.ncGlobalSecondaryColor,
          disabledColor: '#ccc',
          textColor: poweredByTheme.ncGlobalTextColor
        },
        sessionContainerMovie: {
          bgColor: poweredByTheme.ncGlobalContainerColor,
          color: poweredByTheme.ncGlobalTextColor
        },
        seats: {
          ...theme.seats,
          title: {
            color: poweredByTheme.ncGlobalTextColor
          },
          zoom: {
            bgColor: poweredByTheme.ncGlobalTextColor,
            color: poweredByTheme.ncGlobalTextColor
          },
          screenSeatMap: {
            color: poweredByTheme.ncGlobalTextColor,
            bgColor: poweredByTheme.ncGlobalContainerColor
          },
          screen: {
            color: poweredByTheme.ncGlobalBackgroundColor,
            bgColor: poweredByTheme.ncGlobalTextColor,
            border: poweredByTheme.ncGlobalTextColor
          },
          map: {
            ...theme.seats.map,
            common: {
              borderColor: poweredByTheme.ncGlobalPrimaryColor,
              bgColor: poweredByTheme.ncGlobalPrimaryColor,
              color: poweredByTheme.ncGlobalBackgroundColor,
              iconColor: poweredByTheme.ncGlobalBackgroundColor
            },
            selected: {
              color: poweredByTheme.ncGlobalBackgroundColor,
              borderColor: poweredByTheme.ncGlobalSecondaryColor,
              bgColor: poweredByTheme.ncGlobalSecondaryColor
            },
            columnLabelColor: poweredByTheme.ncGlobalPrimaryColor
          }
        },
        subtitleSeats: {
          title: {
            color: poweredByTheme.ncHeaderColor
          },
          bgColor: poweredByTheme.ncGlobalBackgroundColor,
          color: ' #89a'
        },
        warningSession: {
          bgColor: poweredByTheme.ncGlobalTextColor,
          color: poweredByTheme.ncGlobalTextColor,
          icon: {
            color: '#ccc',
            bgColor: poweredByTheme.ncGlobalBackgroundColor
          }
        },
        modal: {
          ...theme.modal,
          bgContentColor: poweredByTheme.ncGlobalContainerColor,
          bgImage: poweredByTheme.ncDarkHeader
            ? '/static/images/backgrounds/dark.jpg'
            : '/static/images/backgrounds/light.jpg',
          titleColor: poweredByTheme.ncGlobalTextColor,
          descriptionColor: poweredByTheme.ncGlobalTextColor
        },
        summary: {
          color: {
            primary: poweredByTheme.ncGlobalTextColor
          },
          header: {
            color: poweredByTheme.ncGlobalTextColor
          },
          bgColor: poweredByTheme.ncGlobalContainerColor,
          icons: {
            secondary: poweredByTheme.ncGlobalSecondaryColor
          },
          tab: {
            color: {
              disabled: poweredByTheme.ncGlobalTextColor
            }
          },
          total: {
            color: poweredByTheme.ncGlobalTextColor
          }
        },
        footer: {
          boxShadow: poweredByTheme.ncGlobalTextColor,
          bgColor: poweredByTheme.ncGlobalContainerColor,
          color: poweredByTheme.ncGlobalTextColor,
          quantity: {
            bgColor: poweredByTheme.ncGlobalPrimaryColor,
            color: poweredByTheme.ncGlobalBackgroundColor
          },
          value: {
            color: poweredByTheme.ncGlobalSecondaryColor
          },
          ticketList: {
            bgColor: poweredByTheme.ncGlobalTextColor
          }
        },
        button: {
          primary: {
            bgColor: poweredByTheme.ncGlobalPrimaryColor,
            color: poweredByTheme.ncGlobalBackgroundColor
          },
          buttonBomboniere: {
            bgColor: poweredByTheme.ncGlobalPrimaryColor,
            color: poweredByTheme.ncGlobalBackgroundColor,
            textActiveColor: poweredByTheme.ncGlobalBackgroundColor,
            backgroundActiveColor: poweredByTheme.ncGlobalPrimaryColor
          },
          gradient: {
            bgColor: poweredByTheme.ncGlobalPrimaryColor,
            loginBgColor: poweredByTheme.ncGlobalPrimaryColor,
            loginColor: poweredByTheme.ncGlobalBackgroundColor,
            color: poweredByTheme.ncGlobalTextColor
          },
          disabled: {
            bgColor: poweredByTheme.ncGlobalTextColor,
            color: poweredByTheme.ncGlobalTextColor
          }
        },
        ticketBox: {
          selectedQtd: {
            bgColor: poweredByTheme.ncGlobalSecondaryColor,
            color: poweredByTheme.ncGlobalPrimaryColor,
            disabled: poweredByTheme.ncGlobalSecondaryColor + '25'
          },
          bgColor: poweredByTheme.ncGlobalContainerColor,
          nameColor: poweredByTheme.ncGlobalTextColor,
          oldPrice: poweredByTheme.ncGlobalTextColor,
          currentPrice: poweredByTheme.ncGlobalTextColor,
          serviceChargeColor: poweredByTheme.ncGlobalTextColor,
          notificationColor: poweredByTheme.ncGlobalSecondaryColor,
          promotionNotificationColor: poweredByTheme.ncGlobalPrimaryColor,
          disabledColor: poweredByTheme.ncGlobalTextColor,
          count: {
            color: poweredByTheme.ncGlobalTextColor
          }
        }
      }
    } else {
      themeCheckout = {
        data: {
          isCheckoutPersonalized: false,
          isPoweredBy: false
        }
      }
    }
    const newTheme: TResponseData = {
      ...theme,
      ...themeCheckout
    }
    return newTheme
  }, [poweredByTheme])
  return (
    <>
      <Head>
        <link
          rel="icon"
          type="image/png"
          href="/static/images/favicon.ico"
          sizes="32x32"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />

        <script defer src={process.env.UOLTM_SCRIPT} />
      </Head>

      <Script
        src="https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js"
        integrity="sha384-yW/Z0kbHxz6O8t90hZELjYemtlByLlDPPoUcbMKaRax6imb9H2CKdnYe29CKk7lh"
        crossOrigin="anonymous"
        strategy="afterInteractive"
      />
      <Script async src="https://tm.jsuol.com.br/modules/dmp-uol-sync.js" />
      <Script
        src={process.env.UOLTM_SCRIPT}
        strategy="afterInteractive"
        onLoad={() => {
          window.universal_variable = window.universal_variable || {}
          window.universal_variable.dfp = window.universal_variable.dfp || {}
          window.uolads = window.uolads || []
          uolAdsLoaded && uolAdsLoaded(true)
        }}
      />
      <Script src={process.env.ADYEN_SCRIPT} strategy="lazyOnload" />

      <QueryClientProvider client={queryClient}>
        <CookiesProvider>
          {poweredByTheme && (
            <ThemeProvider theme={getTheme()}>
              <GlobalStyle />
              <GlobalStateProvider>
                <AdyenCheckoutProvider>
                  <ReCaptchaProvider>
                    <LoginProvider>
                      <PageTransition timeout={400}>
                        <Component {...pageProps} key={router.route} />
                      </PageTransition>
                    </LoginProvider>
                  </ReCaptchaProvider>
                </AdyenCheckoutProvider>
              </GlobalStateProvider>
            </ThemeProvider>
          )}
        </CookiesProvider>
      </QueryClientProvider>
    </>
  )
}

export default MyApp
