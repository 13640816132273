import { Cookies } from 'react-cookie'
import CONSTANTS from '@Constants/index'
import { getCurrentDomainCookie } from '@Helpers/others'
const cookies = new Cookies()

export function useCookies() {
  const configOptions = {
    path: CONSTANTS.COOKIE.PATH,
    domain: getCurrentDomainCookie()
  }
  const removeCookie = async (name: string, customDomain?: string) => {
    return customDomain
      ? cookies.remove(name, {
          path: CONSTANTS.COOKIE.PATH,
          domain: customDomain
        })
      : cookies.remove(name, configOptions)
  }
  const setCookie = (
    name: string,
    data: string | object,
    cookieExpirationTime?: Date
  ) => {
    const options = { ...configOptions }
    if (cookieExpirationTime) options['expires'] = cookieExpirationTime

    return cookies.set(name, data, options)
  }
  const getCookie = (name: string) => {
    return cookies.get(name)
  }
  const removeAllCookies = () => {
    const {
      CART_SESSION_ID,
      CART_ID,
      USER_ID,
      CART_SECTION_ID,
      TRACKING_PAGE_INFO,
      CURRENT_CITY,
      SITE_CITY,
      PAGE
    } = CONSTANTS.COOKIE
    removeCookie(USER_ID)
    removeCookie(CART_ID)
    removeCookie(CART_SESSION_ID)
    removeCookie(CART_SECTION_ID)
    removeCookie(TRACKING_PAGE_INFO)
    removeCookie(CURRENT_CITY)
    removeCookie(SITE_CITY)
    removeCookie(PAGE)
  }

  return {
    removeCookie,
    removeAllCookies,
    setCookie,
    getCookie,
    cookies
  }
}
