// this file will receive receive the object config to: dark | light | poweredby configs
// the object below is just a model example used actually in aplication to dark | light models

import { TResponseData } from '@shared/interfaces/PoweredBy'

import customTheme from './dark'
// import customTheme from './light'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const theme: TResponseData | any = customTheme
export default theme
