import { css } from 'styled-components'

const base = css`
  html {
    background: ${(props) => props.theme?.global?.background?.color};
  }
  small {
    font-size: 14px;
  }
  p {
    line-height: 20px;
  }
  body {
    font-family: ProximaNova-Regular, 'Trebuchet MS', Klavika-Regular,
      Klavika-Bold, sans-serif, Arial;
    margin: 0;
    padding: 0;
    a {
      color: ${(props) => props.theme?.global?.link?.color};
      text-decoration: none;
      display: inline-block;
    }
  }
`

export default base
