import React, { ReactNode } from 'react'
import { PageTransition } from 'next-page-transitions'

const Component = (props: { children: ReactNode; timeout: number }) => {
  return (
    <>
      <PageTransition timeout={props.timeout} classNames="page-transition">
        {props.children}
      </PageTransition>
      <style jsx>
        {`
          .page-transition-enter {
            opacity: 0;
            transform: translate3d(0, 20px, 0);
          }
          .page-transition-enter-active {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            transition: opacity ${props.timeout}ms, transform ${props.timeout}ms;
          }
          .page-transition-exit {
            opacity: 1;
          }
          .page-transition-exit-active {
            opacity: 0;
            transition: opacity ${props.timeout}ms;
          }
        `}
      </style>
    </>
  )
}

export default Component
