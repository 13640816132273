type TActionMap<M extends { [index: string]: unknown }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

export enum ECARDTYPES {
  BIN = 'BIN',
  SUMMARY = 'SUMMARY',
  BRAND = 'BRAND',
  CARD_EXPRESS = 'CARD_EXPRESS',
  GOOGLE_PAY = 'GOOGLE_PAY',
  PIX = 'PIX',
  NUPAY = 'NUPAY'
}

export interface ICARDVALUES {
  summary: string
  brand: string
  bin: string
  data: {}
}

type TCardValuesPayload = {
  [ECARDTYPES.BIN]: {
    bin: string
  }
  [ECARDTYPES.SUMMARY]: {
    summary: string
    cardType: string
  }
  [ECARDTYPES.BRAND]: {
    brand: string
  }
  [ECARDTYPES.CARD_EXPRESS]: {
    data: {}
  }
  [ECARDTYPES.GOOGLE_PAY]: {
    name: string
    label: string
    cardType: string
  }
  [ECARDTYPES.PIX]: {
    name: string
    label: string
    cardType: string
  }
  [ECARDTYPES.NUPAY]: {
    name: string
    label: string
    cardType: string
  }
}

export type TCardValuesActions =
  TActionMap<TCardValuesPayload>[keyof TActionMap<TCardValuesPayload>]

export const initialState: ICARDVALUES = {
  brand: '',
  summary: '',
  bin: '',
  data: {}
}

export const cardValuesReducer = (
  state: ICARDVALUES,
  action: TCardValuesActions
) => {
  switch (action.type) {
    case ECARDTYPES.BRAND:
      return {
        ...state,
        brand: action.payload.brand
      }
    case ECARDTYPES.SUMMARY:
      return {
        ...state,
        summary: action.payload.summary,
        cardType: action.payload.cardType
      }
    case ECARDTYPES.BIN:
      return {
        ...state,
        bin: action.payload.bin
      }
    case ECARDTYPES.CARD_EXPRESS:
      return {
        ...state,
        data: action.payload.data
      }
    case ECARDTYPES.GOOGLE_PAY:
      return {
        ...state,
        name: action.payload.name,
        label: action.payload.label,
        cardType: action.payload.cardType
      }
    case ECARDTYPES.PIX:
      return {
        ...state,
        name: action.payload.name,
        label: action.payload.label,
        cardType: action.payload.cardType
      }
    case ECARDTYPES.NUPAY:
      return {
        ...state,
        name: action.payload.name,
        label: action.payload.label,
        cardType: action.payload.cardType
      }
    default:
      return state
  }
}
