import colors from './colors'

export const dark = {
  data: {
    isCheckoutPersonalized: false,
    isPoweredBy: false,
    url: ''
  },
  global: {
    primary: {
      bgColor: colors.NeutralColors.NightRider,
      color: colors.NeutralColors.Gray60
    },
    secondary: {
      color: colors.NeutralColors.White
    },
    tertiary: {
      color: colors.NeutralColors.Gray60
    },
    four: {
      color: colors.NeutralColors.Gray80
    },
    link: {
      color: colors.DodgerBlue
    },
    text: {
      color: colors.NeutralColors.LightGray3
    },
    background: {
      color: colors.NeutralColors.NightRider
    },
    placemark: {
      color: colors.buttonGrandientPrimary
    }
  },
  images: {
    bg: '',
    bgMobile: '',
    footer: ''
  },
  header: {
    bgColor: `${colors.NeutralColors.DarkGray}`,
    icons: {
      color: colors.DodgerBlue
    },
    user: {
      bgColor: colors.NeutralColors.NightRider,
      borderColor: colors.DodgerBlue
    },
    text: {
      color: colors.NeutralColors.White
    }
  },
  button: {
    primary: {
      bgColor: colors.DodgerBlue,
      color: colors.NeutralColors.DarkGray
    },
    buttonBomboniere: {
      bgColor: colors.buttonBomboniereBorder,
      color: colors.DodgerBlue,
      backgroundActiveColor: colors.primary2,
      textActiveColor: colors.NeutralColors.VeryLightGray2
    },
    gradient: {
      bgColor: `linear-gradient(to right, ${colors.buttonGrandientPrimary}, ${colors.buttonGrandientSecondary})`,
      loginBgColor: `linear-gradient(270deg, #6C04BA 0%, #3255E2 100%)`,
      loginColor: colors.NeutralColors.White,
      color: colors.NeutralColors.DarkGray
    },
    disabled: {
      bgColor: colors.NeutralColors.Gray40,
      color: colors.NeutralColors.DarkGray
    }
  },
  alert: {
    danger: {
      bgColor: '#5c3329',
      borderLeft: `4px solid ${colors.Scarlet}`,
      titleColor: colors.Scarlet,
      contentColor: colors.NeutralColors.White,
      iconColor: colors.Scarlet,
      iconCloseColor: colors.NeutralColors.White
    },
    success: {
      bgColor: '#A3ECA3',
      borderLeft: `4px solid ${colors.FreeSpeech}`,
      titleColor: colors.FreeSpeech,
      contentColor: colors.NeutralColors.White,
      iconColor: colors.FreeSpeech,
      iconCloseColor: '#00CC00'
    },
    warning: {
      bgColor: '#5c4729',
      borderLeft: `4px solid ${colors.OrangePeel}`,
      titleColor: colors.OrangePeel,
      contentColor: colors.NeutralColors.White,
      iconColor: colors.buttonGrandientPrimary,
      iconCloseColor: '#09f'
    },
    info: {
      bgColor: '#29475c',
      borderLeft: `4px solid ${colors.DodgerBlue}`,
      titleColor: colors.DodgerBlue,
      contentColor: colors.NeutralColors.White,
      iconColor: colors.DodgerBlue,
      iconCloseColor: '#09f'
    }
  },
  modal: {
    bgColor: colors.NeutralColors.DarkGray80,
    bgContentColor: colors.NeutralColors.NightRider,
    bgImage: '/static/images/backgrounds/dark.jpg',
    titleColor: colors.NeutralColors.White,
    descriptionColor: colors.NeutralColors.White
  },
  subtitleSeats: {
    title: {
      color: colors.NeutralColors.LightGray3
    },
    bgColor: 'none',
    color: colors.NeutralColors.LightGray3
  },
  warningSession: {
    bgColor: colors.NeutralColors.LightGray3,
    color: colors.NeutralColors.LightGray3,
    icon: {
      color: colors.NeutralColors.LightGray3,
      bgColor: colors.NeutralColors.LightGray3
    }
  },
  ticketBox: {
    bgColor: colors.NeutralColors.DarkGray,
    nameColor: colors.NeutralColors.White,
    oldPrice: '#89A',
    currentPrice: colors.NeutralColors.VeryLightGray2,
    serviceChargeColor: colors.NeutralColors.Gray60,
    notificationColor: colors.NeutralColors.Gray80,
    promotionNotificationColor: colors.NeutralColors.White,
    disabledColor: colors.NeutralColors.Gray60,
    count: {
      color: colors.NeutralColors.VeryLightGray2
    },
    selectedQtd: {
      bgColor: colors.DodgerBlue,
      color: colors.NeutralColors.NightRider,
      disabled: 'rgba(152, 170, 236, 0.25)'
    },
    plus: {
      color: colors.NeutralColors.Gray80
    },
    minus: {
      color: colors.NeutralColors.Gray80
    }
  },
  sessionContainerMovie: {
    bgColor: colors.NeutralColors.DarkGray,
    color: colors.NeutralColors.White,
    hourSession: {
      color: colors.NeutralColors.White
    },
    dateSession: {
      color: colors.NeutralColors.White
    }
  },
  seats: {
    title: {
      color: colors.NeutralColors.VeryLightGray2
    },
    zoom: {
      bgColor: colors.NeutralColors.DarkGray,
      color: colors.DodgerBlue
    },
    screenSeatMap: {
      color: colors.NeutralColors.Gray40,
      bgColor: colors.NeutralColors.DarkGray
    },
    screen: {
      color: '#212632',
      bgColor: colors.NeutralColors.Gray80,
      border: colors.NeutralColors.VeryLightGray2
    },
    map: {
      common: {
        borderColor: colors.DodgerBlue,
        bgColor: colors.DodgerBlue,
        color: colors.ageRating.Black,
        iconColor: colors.ageRating.Black
      },
      selected: {
        color: colors.ageRating.Black,
        borderColor: colors.SafetyOrange,
        bgColor: colors.SafetyOrange
      },
      disabled: {
        borderColor: colors.NeutralColors.Gray40,
        bgColor: colors.NeutralColors.Gray40,
        iconPathColor: colors.NeutralColors.NightRider,
        iconColor: colors.NeutralColors.NightRider
      },
      blocked: {
        color: colors.NeutralColors.Gray80
      },
      columnLabelColor: colors.NeutralColors.White
    }
  },
  tabs: {
    bgActive: colors.primary2,
    bgDefault: 'transparent',
    activeColor: colors.NeutralColors.White,
    disabledColor: colors.NeutralColors.StrokeTabs,
    textColor: colors.NeutralColors.Gray80,
    availableColor: colors.DodgerBlue
  },
  toggleButton: {
    activeColor: '#0d0',
    disabledColor: colors.NeutralColors.Gray40
  },
  footer: {
    boxShadow: colors.NeutralColors.DarkGray,
    bgColor: colors.NeutralColors.StrokeTabs,
    color: colors.NeutralColors.Gray80,
    quantity: {
      bgColor: colors.SafetyOrange,
      color: colors.ageRating.Black
    },
    value: {
      color: colors.NeutralColors.White
    },
    ticketList: {
      bgColor: 'rgba(33, 38, 45, 0.90)'
    }
  },

  summary: {
    color: {
      primary: colors.NeutralColors.White
    },
    header: {
      color: colors.NeutralColors.White
    },
    bgColor: colors.NeutralColors.StrokeTabs,
    icons: {
      secondary: colors.DodgerBlue
    },
    tab: {
      color: {
        disabled: colors.NeutralColors.Gray40
      }
    }
  },
  login: {
    bgColor: colors.NeutralColors.DarkGray
  },
  driveIn: {
    bg: colors.NeutralColors.Nero,
    borderColor: colors.NeutralColors.Nero,
    iconCarColor: colors.NeutralColors.White,
    iconPathColor: colors.NeutralColors.Nero,
    labelColor: colors.ageRating.Black,
    shapeBg: colors.SafetyOrange,
    shapeBgDisabled: colors.NeutralColors.Gray40,
    msgColor: colors.NeutralColors.White
  },
  payment: {
    giftcard: {
      colorHeader: colors.DodgerBlue
    },
    border: {
      color: colors.NeutralColors.NightRider
    }
  }
}

export default dark
