import getConfig from 'next/config'

export const INGRESSO =
  getConfig()?.publicRuntimeConfig.ING_HOST || 'http://localhost:3000'

export const CUSTOMER_SERVICE = 'https://atendimento.ingresso.com/'

const CUSTOMER_SERVICE_PORTAL = `${CUSTOMER_SERVICE}portal/pt-br/kb/articles`

export const TERMS_OF_USE = `${CUSTOMER_SERVICE_PORTAL}/termos-de-uso`

export const PRIVACY_POLICY = `${CUSTOMER_SERVICE_PORTAL}/pol%C3%ADtica-de-privacidade`

export const REMOVE_ACCOUNT = `${CUSTOMER_SERVICE_PORTAL}/quero-excluir-minha-conta-como-faço`
