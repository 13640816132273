export const translations = {
  'en-US': {
    payButton: 'Pagar',
    'payButton.redirecting': 'Redirecionando...',
    'payButton.with': 'Pague %{value} com %{maskedData}',
    close: 'Fechar',
    storeDetails: 'Salvar para meu próximo pagamento',
    'creditCard.holderName': 'Nome no cartão',
    'creditCard.holderName.placeholder': 'Nome como está no cartão',
    'creditCard.holderName.invalid':
      'Digite o nome conforme mostrado no cartão',
    'creditCard.numberField.title': 'Número do cartão',
    'creditCard.expiryDateField.title': 'Data de validade',
    'creditCard.expiryDateField.placeholder': 'MM/AA',
    'creditCard.expiryDateField.month': 'Mês',
    'creditCard.expiryDateField.month.placeholder': 'MM',
    'creditCard.expiryDateField.year.placeholder': 'AA',
    'creditCard.expiryDateField.year': 'Ano',
    'creditCard.cvcField.title': 'Código de segurança',
    'creditCard.storeDetailsButton': 'Lembrar para a próxima vez',
    'creditCard.cvcField.placeholder.4digits': '4 dígitos',
    'creditCard.cvcField.placeholder.3digits': '3 dígitos',
    'creditCard.taxNumber.placeholder': 'AAAMMDD / 0123456789',
    installments: 'Opções de Parcelamento',
    installmentOption: '%{times}x %{partialValue}',
    installmentOptionMonths: '%{times} meses',
    'installments.oneTime': 'Pagamento à vista',
    'installments.installments': 'Pagamento parcelado',
    'installments.revolving': 'Pagamento rotativo',
    'sepaDirectDebit.ibanField.invalid': 'Número de conta inválido',
    'sepaDirectDebit.nameField.placeholder': 'J. Silva',
    'sepa.ownerName': 'Nome do titular da conta bancária',
    'sepa.ibanNumber': 'Número de conta (NIB)',
    'error.title': 'Erro',
    'error.subtitle.redirect': 'Falha no redirecionamento',
    'error.subtitle.payment': 'Falha no pagamento',
    'error.subtitle.refused': 'Pagamento recusado',
    'error.message.unknown': 'Ocorreu um erro desconhecido',
    'errorPanel.title': 'Erros existentes',
    'idealIssuer.selectField.title': 'Banco',
    'idealIssuer.selectField.placeholder': 'Selecione seu banco',
    'creditCard.success': 'Pagamento bem-sucedido',
    loading: 'Carregando...',
    continue: 'Continuar',
    continueTo: 'Continuar para',
    'wechatpay.timetopay': 'Você tem %@ para pagar',
    'sr.wechatpay.timetopay': 'Você tem %#minutos%# e %#segundos%# para pagar',
    'wechatpay.scanqrcode': 'Escanear código QR',
    personalDetails: 'Informações pessoais',
    companyDetails: 'Dados da empresa',
    'companyDetails.name': 'Nome da empresa',
    'companyDetails.registrationNumber': 'Número de registro',
    socialSecurityNumber: 'CPF',
    firstName: 'Nome',
    'firstName.invalid': 'Digite seu nome',
    infix: 'Prefixo',
    lastName: 'Sobrenome',
    'lastName.invalid': 'Digite seu sobrenome',
    mobileNumber: 'Celular',
    'mobileNumber.invalid': 'Número de celular inválido',
    city: 'Cidade',
    postalCode: 'CEP',
    'postalCode.optional': 'Código postal (opcional)',
    countryCode: 'Código do país',
    telephoneNumber: 'Número de telefone',
    dateOfBirth: 'Data de nascimento',
    shopperEmail: 'Endereço de e-mail',
    gender: 'Gênero',
    'gender.notselected': 'Selecione seu gênero',
    male: 'Masculino',
    female: 'Feminino',
    billingAddress: 'Endereço de cobrança',
    street: 'Rua',
    stateOrProvince: 'Estado ou província',
    country: 'País',
    houseNumberOrName: 'Número da casa',
    separateDeliveryAddress: 'Especificar um endereço de entrega separado',
    deliveryAddress: 'Endereço de entrega',
    zipCode: 'Código postal',
    apartmentSuite: 'Apartamento/Conjunto',
    provinceOrTerritory: 'Província ou território',
    cityTown: 'Cidade',
    address: 'Endereço',
    'address.placeholder': 'Encontre seu endereço',
    'address.errors.incomplete': 'Insira um endereço para continuar',
    'address.enterManually': 'Inserir endereço manualmente',
    state: 'Estado',
    'field.title.optional': '(opcional)',
    'creditCard.cvcField.title.optional': 'Código de segurança (opcional)',
    'issuerList.wallet.placeholder': 'Selecione uma carteira',
    privacyPolicy: 'Política de Privacidade',
    'afterPay.agreement': 'Eu concordo com as %@ do AfterPay',
    paymentConditions: 'condições de pagamento',
    openApp: 'Abrir o aplicativo',
    'voucher.readInstructions': 'Leia as instruções',
    'voucher.introduction':
      'Obrigado pela sua compra, use o cupom a seguir para concluir o seu pagamento.',
    'voucher.expirationDate': 'Data de validade',
    'voucher.alternativeReference': 'Referência alternativa',
    'dragonpay.voucher.non.bank.selectField.placeholder':
      'Selecione o seu fornecedor',
    'dragonpay.voucher.bank.selectField.placeholder': 'Selecione seu banco',
    'voucher.paymentReferenceLabel': 'Referência de pagamento',
    'voucher.surcharge': 'Inclui %@ de sobretaxa',
    'voucher.introduction.doku':
      'Obrigado pela sua compra, use a informação a seguir para concluir o seu pagamento.',
    'voucher.shopperName': 'Nome do consumidor',
    'voucher.merchantName': 'Comerciante online',
    'voucher.introduction.econtext':
      'Obrigado pela sua compra, use a informação a seguir para concluir o seu pagamento.',
    'voucher.telephoneNumber': 'Número de telefone',
    'voucher.shopperReference': 'Referência do consumidor',
    'voucher.collectionInstitutionNumber': 'Número da instituição de cobrança',
    'voucher.econtext.telephoneNumber.invalid':
      'O número de telefone deve ter 10 ou 11 dígitos',
    'boletobancario.btnLabel': 'Gerar Boleto',
    'boleto.sendCopyToEmail': 'Enviar uma cópia por e-mail',
    'button.copy': 'Copiar',
    'button.download': 'Baixar',
    'boleto.socialSecurityNumber.invalid': 'O campo não é válido',
    'creditCard.storedCard.description.ariaLabel':
      'O cartão armazenado termina em %@',
    'voucher.entity': 'Entidade',
    donateButton: 'Doar',
    notNowButton: 'Agora não',
    thanksForYourSupport: 'Obrigado pelo apoio!',
    preauthorizeWith: 'Pré-autorizar com',
    confirmPreauthorization: 'Confirmar pré-autorização',
    confirmPurchase: 'Confirmar compra',
    applyGiftcard: 'Resgatar',
    giftcardBalance: 'Saldo do vale-presente',
    deductedBalance: 'Saldo debitado',
    'creditCard.pin.title': 'Pin',
    'creditCard.encryptedPassword.label':
      'Primeiros dois dígitos da senha do cartão',
    'creditCard.encryptedPassword.invalid': 'Senha inválida',
    'creditCard.taxNumber':
      'Data de nascimento do titular do cartão ou número de registro corporativo',
    'creditCard.taxNumber.label':
      'Data de nascimento do titular do cartão (AAMMDD) ou número de registro corporativo (10 dígitos)',
    'creditCard.taxNumber.labelAlt':
      'Número de registro corporativo (10 dígitos)',
    'creditCard.taxNumber.invalid':
      'Data de nascimento do titular do cartão ou número de registro corporativo inválidos',
    'storedPaymentMethod.disable.button': 'Remover',
    'storedPaymentMethod.disable.confirmation':
      'Remover método de pagamento armazenado',
    'storedPaymentMethod.disable.confirmButton': 'Sim, remover',
    'storedPaymentMethod.disable.cancelButton': 'Cancelar',
    'ach.bankAccount': 'Conta bancária',
    'ach.accountHolderNameField.title': 'Nome do titular da conta',
    'ach.accountHolderNameField.placeholder': 'J. Smith',
    'ach.accountHolderNameField.invalid': 'Nome do titular da conta inválido',
    'ach.accountNumberField.title': 'Número da conta',
    'ach.accountNumberField.invalid': 'Número de conta inválido',
    'ach.accountLocationField.title': 'Número de roteamento ABA',
    'ach.accountLocationField.invalid': 'Número de roteamento ABA inválido',
    'ach.savedBankAccount': 'Conta bancária cadastrada',
    'select.state': 'Selecionar estado',
    'select.stateOrProvince': 'Selecione estado ou província',
    'select.provinceOrTerritory': 'Selecionar província ou território',
    'select.country': 'Selecione o país',
    'select.noOptionsFound': 'Nenhuma opção encontrada',
    'select.filter.placeholder': 'Pesquisar...',
    'telephoneNumber.invalid': 'Número de telefone inválido',
    qrCodeOrApp: 'ou',
    'paypal.processingPayment': 'Processando pagamento...',
    generateQRCode: 'Gerar código QR',
    'await.waitForConfirmation': 'Aguardando confirmação',
    'mbway.confirmPayment': 'Confirme seu pagamento no aplicativo MB WAY',
    'shopperEmail.invalid': 'Endereço de e-mail inválido',
    'dateOfBirth.format': 'DD/MM/AAAA',
    'dateOfBirth.invalid':
      'Insira uma data de nascimento válida que indique que você tem pelo menos 18 anos',
    'blik.confirmPayment':
      'Abra o aplicativo do seu banco para confirmar o pagamento.',
    'blik.invalid': 'Digite 6 números',
    'blik.code': 'Código de 6 dígitos',
    'blik.help': 'Obtenha o código no aplicativo do seu banco.',
    'swish.pendingMessage':
      'Depois de escanear o QR, o status pode ficar pendente por até 10 minutos. Não tente refazer o pagamento antes desse período para evitar cobrança duplicada.',
    'field.valid': 'Campo válido',
    'field.invalid': 'Campo inválido',
    'error.va.gen.01': 'Campo incompleto',
    'error.va.gen.02': 'Campo inválido',
    'error.va.sf-cc-num.01': 'Digite um número de cartão válido',
    'error.va.sf-cc-num.02': 'Digite o número do cartão',
    'error.va.sf-cc-num.03': 'Digite uma bandeira de cartão aceita',
    'error.va.sf-cc-num.04': 'Digite o número completo do cartão',
    'error.va.sf-cc-dat.01': 'Digite uma data válida',
    'error.va.sf-cc-dat.02': 'Digite uma data válida',
    'error.va.sf-cc-dat.03': 'Cartão de crédito prestes a vencer',
    'error.va.sf-cc-dat.04': 'Digite a data de validade',
    'error.va.sf-cc-dat.05': 'Digite a data de validade completa',
    'error.va.sf-cc-mth.01': 'Digite o mês de validade',
    'error.va.sf-cc-yr.01': 'Digite o ano de validade',
    'error.va.sf-cc-yr.02': 'Digite o ano de validade completo',
    'error.va.sf-cc-cvc.01': 'Digite o código de segurança',
    'error.va.sf-cc-cvc.02': 'Digite o código de segurança completo',
    'error.va.sf-ach-num.01': 'O campo do número da conta bancária está vazio',
    'error.va.sf-ach-num.02':
      'O número da conta bancária tem o comprimento errado',
    'error.va.sf-ach-loc.01':
      'O campo do número de identificação do banco está vazio',
    'error.va.sf-ach-loc.02':
      'O número de identificação do banco tem o comprimento errado',
    'error.va.sf-kcp-pwd.01': 'O campo da senha está vazio',
    'error.va.sf-kcp-pwd.02': 'A senha tem o comprimento errado',
    'error.giftcard.no-balance': 'Este vale-presente tem saldo zero',
    'error.giftcard.card-error':
      'Não existe um vale-presente com esse número em nossos registros',
    'error.giftcard.currency-error':
      'Os vales-presente são válidos somente na moeda em que foram emitidos',
    'amazonpay.signout': 'Sair do Amazon',
    'amazonpay.changePaymentDetails': 'Alterar dados de pagamento',
    'partialPayment.warning':
      'Selecione outro método de pagamento para pagar o restante',
    'partialPayment.remainingBalance': 'O saldo restante será %{amount}',
    'bankTransfer.beneficiary': 'Beneficiário',
    'bankTransfer.iban': 'IBAN',
    'bankTransfer.bic': 'BIC',
    'bankTransfer.reference': 'Referência',
    'bankTransfer.introduction':
      'Continue criando o novo pagamento por transferência bancária. Use as informações na tela a seguir para concluí-lo.',
    'bankTransfer.instructions':
      'Obrigado pela sua compra, use a informação a seguir para concluir o seu pagamento.',
    'bacs.accountHolderName': 'Nome do titular da conta bancária',
    'bacs.accountHolderName.invalid':
      'Nome do titular da conta bancária inválido',
    'bacs.accountNumber': 'Número da conta bancária',
    'bacs.accountNumber.invalid': 'Número da conta bancária inválido',
    'bacs.bankLocationId': 'Código de classificação',
    'bacs.bankLocationId.invalid': 'Código de classificação inválido',
    'bacs.consent.amount':
      'Concordo que o valor acima seja deduzido da minha conta bancária.',
    'bacs.consent.account':
      'Confirmo que a conta está em meu nome e que sou o único signatário que deve autorizar o débito direto nessa conta.',
    edit: 'Editar',
    'bacs.confirm': 'Confirmar e pagar',
    'bacs.result.introduction': 'Baixar instrução de débito direto (DDI)',
    'download.pdf': 'Baixar PDF',
    'creditCard.encryptedCardNumber.aria.iframeTitle':
      'Iframe para número do cartão',
    'creditCard.encryptedCardNumber.aria.label': 'Número do cartão',
    'creditCard.encryptedExpiryDate.aria.iframeTitle':
      'Iframe para data de validade',
    'creditCard.encryptedExpiryDate.aria.label': 'Data de validade',
    'creditCard.encryptedExpiryMonth.aria.iframeTitle':
      'Iframe para mês de validade',
    'creditCard.encryptedExpiryMonth.aria.label': 'Mês de vencimento',
    'creditCard.encryptedExpiryYear.aria.iframeTitle':
      'Iframe para ano de validade',
    'creditCard.encryptedExpiryYear.aria.label': 'Ano de vencimento',
    'creditCard.encryptedSecurityCode.aria.iframeTitle':
      'Iframe para código de segurança',
    'creditCard.encryptedSecurityCode.aria.label': 'Código de segurança',
    'creditCard.encryptedPassword.aria.iframeTitle': 'Iframe para senha',
    'creditCard.encryptedPassword.aria.label':
      'Primeiros dois dígitos da senha do cartão',
    'giftcard.encryptedCardNumber.aria.iframeTitle':
      'Iframe para número do cartão',
    'giftcard.encryptedCardNumber.aria.label': 'Número do cartão',
    'giftcard.encryptedSecurityCode.aria.iframeTitle': 'Iframe para pin',
    'giftcard.encryptedSecurityCode.aria.label': 'Pin',
    giftcardTransactionLimit:
      'Máximo de %{amount} permitido por transação neste cartão-presente',
    'ach.encryptedBankAccountNumber.aria.iframeTitle':
      'Iframe para número da conta bancária',
    'ach.encryptedBankAccountNumber.aria.label': 'Número da conta',
    'ach.encryptedBankLocationId.aria.iframeTitle':
      'Iframe para número de identificação do banco',
    'ach.encryptedBankLocationId.aria.label': 'Número de roteamento ABA',
    'pix.instructions':
      'Abra o app com sua chave PIX cadastrada, escolha Pagar com Pix e escaneie o QR Code ou copie e cole o código',
    'twint.saved': 'salvo',
    orPayWith: 'ou pague com',
    invalidFormatExpects: 'Formato inválido. Formato esperado: %{format}',
    'upi.qrCodeWaitingMessage':
      'Escaneie o QR code com o aplicativo UPI de sua preferência para concluir o pagamento',
    'upi.vpaWaitingMessage': 'Abra o aplicativo UPI para confirmar o pagamento',
    'upi.modeSelection': 'Selecione como você gostaria de usar o UPI.',
    'onlineBanking.termsAndConditions':
      'Ao continuar, você concorda com os %#termos e condições%#',
    'onlineBankingPL.termsAndConditions':
      'Ao continuar, você concorda com as %#condições%# e as %#informações obrigatórias%# da Przelewy24',
    'ctp.loading.poweredByCtp': 'Desenvolvido por Click to Pay',
    'ctp.loading.intro':
      'Estamos verificando se você tem algum cartão salvo no Click to Pay...',
    'ctp.login.title': 'Continuar para o Click to Pay',
    'ctp.login.subtitle':
      'Digite o endereço de e-mail associado ao Click to Pay para continuar.',
    'ctp.login.inputLabel': 'E-mail',
    'ctp.logout.notYou': 'Não é você?',
    'ctp.logout.notYourCards': 'Não é o seu cartão?',
    'ctp.logout.notYourCard': 'Não é o seu cartão?',
    'ctp.logout.notYourProfile': 'Não é o seu perfil?',
    'ctp.otp.fieldLabel': 'Código de acesso único',
    'ctp.otp.resendCode': 'Reenviar código',
    'ctp.otp.codeResent': 'Código reenviado',
    'ctp.otp.title': 'Acesse seus cartões Click to Pay',
    'ctp.otp.subtitle':
      'Digite o código %@ enviado para %@ para confirmar que é você.',
    'ctp.emptyProfile.message':
      'Não há nenhum cartão cadastrado neste perfil do Click to Pay',
    'ctp.separatorText': 'ou use',
    'ctp.cards.title': 'Concluir o pagamento com o Click to Pay',
    'ctp.cards.subtitle': 'Selecione um cartão para usar.',
    'ctp.cards.expiredCard': 'Vencido',
    'ctp.manualCardEntry': 'Entrada manual do cartão',
    'ctp.aria.infoModalButton': 'O que é Click to Pay',
    'ctp.infoPopup.title':
      'O Click to Pay traz a facilidade do pagamento online sem contato',
    'ctp.infoPopup.subtitle':
      'Um método de pagamento rápido e seguro, compatível com Mastercard, Visa e outros cartões.',
    'ctp.infoPopup.benefit1':
      'O Click to Pay usa criptografia para manter suas informações seguras e protegidas',
    'ctp.infoPopup.benefit2': 'Use com comerciantes no mundo todo',
    'ctp.infoPopup.benefit3':
      'Configure apenas uma vez e simplifique seus pagamentos no futuro',
    'ctp.errors.AUTH_INVALID': 'Autenticação inválida',
    'ctp.errors.NOT_FOUND':
      'Nenhuma conta foi encontrada. Informe um e-mail válido ou continue inserindo os dados do cartão manualmente',
    'ctp.errors.ID_FORMAT_UNSUPPORTED': 'Formato não compatível',
    'ctp.errors.FRAUD': 'A conta do usuário foi bloqueada ou desativada',
    'ctp.errors.CONSUMER_ID_MISSING':
      'A identidade do consumidor está ausente na solicitação',
    'ctp.errors.ACCT_INACCESSIBLE':
      'Esta conta não está disponível no momento. Ela pode estar bloqueada, por exemplo',
    'ctp.errors.CODE_INVALID': 'Código de verificação incorreto',
    'ctp.errors.CODE_EXPIRED': 'Esse código expirou',
    'ctp.errors.RETRIES_EXCEEDED':
      'O limite de tentativas para gerar a senha de uso único foi excedido',
    'ctp.errors.OTP_SEND_FAILED':
      'Não foi possível enviar a senha de uso único ao destinatário',
    'ctp.errors.REQUEST_TIMEOUT':
      'Algo deu errado. Tente de novo ou insira os dados do cartão manualmente',
    'ctp.errors.UNKNOWN_ERROR':
      'Algo deu errado. Tente de novo ou insira os dados do cartão manualmente',
    'ctp.errors.SERVICE_ERROR':
      'Algo deu errado. Tente de novo ou insira os dados do cartão manualmente',
    'ctp.errors.SERVER_ERROR':
      'Algo deu errado. Tente de novo ou insira os dados do cartão manualmente',
    'ctp.errors.INVALID_PARAMETER':
      'Algo deu errado. Tente de novo ou insira os dados do cartão manualmente',
    'ctp.errors.AUTH_ERROR':
      'Algo deu errado. Tente de novo ou insira os dados do cartão manualmente',
    'paymentMethodsList.aria.label': 'Escolha um método de pagamento',
    'companyDetails.name.invalid': 'Digite o nome da empresa',
    'companyDetails.registrationNumber.invalid': 'Digite o número de registro',
    'consent.checkbox.invalid':
      'Você precisa concordar com os termos e condições',
    'form.instruction':
      'Todos os campos são obrigatórios, a menos que marcados em contrário.',
    'trustly.descriptor': 'Pagamento instantâneo',
    'trustly.description1':
      'Pague diretamente de qualquer conta, com a segurança do seu banco',
    'trustly.description2':
      'Sem cartão, sem download de aplicativo, sem cadastro',
    'ancv.input.label': 'Sua identificação da ANCV',
    'ancv.confirmPayment':
      'Use o aplicativo da ANCV para confirmar o pagamento.',
    'ancv.form.instruction':
      'É necessário ter o aplicativo da Cheque-Vacances para validar este pagamento.',
    'ancv.beneficiaryId.invalid':
      'Insira um endereço de e-mail válido ou uma ID da ANCV'
  }
}
