/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from 'use-context-selector'
import {
  IPropsGlobalContext,
  TModal,
  TAlert,
  TCartData,
  TTickets,
  IAuthToken,
  TTimer,
  TBombonieres,
  IDiscount,
  IFidelity,
  TPaymentAsyncInfo
} from './interface'
import { useCallback, useState } from 'react'
import { Cookies } from 'react-cookie'
import CONSTANTS from '@Constants/index'
import { TSeatsPageData, TSessionData } from './interface'
import { ISeatInfo } from '@Components/Seat/interface'
import {
  getCurrentDomainCookie,
  getTotalQuantityTickets
} from '@Helpers/others'
import useIsClient from '@shared/hooks/useIsClient'
import { useWebView } from '@shared/hooks/useWebView'

export const GlobalContext = createContext<IPropsGlobalContext>(null)

const cookies = new Cookies()

export const GlobalStateProvider = ({ children }) => {
  const { isClient } = useIsClient()

  const [authToken, setAuthToken] = useState<IAuthToken>(
    () =>
      cookies.get(process.env.COOKIE_TOKEN_NAME) ||
      cookies.get(process.env.COOKIE_TOKEN_NAME_APP)
  )

  const [modal, setModal] = useState<TModal>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false)
  const [cartIsReady, setCartIsReady] = useState<boolean>(false)
  const [uolAds, setUolAds] = useState(false)
  const [showModalChallenge, setShowModalChallenge] = useState(false)
  const [hasEffectRun, setHasEffectRun] = useState(false)
  const [showCaptchaSeatMap, setShowCaptchaSeatMap] = useState<boolean>(false)

  const [bomboniere, setBomboniere] = useState<TBombonieres>({
    all: [],
    selected: [],
    totalSelected: 0,
    selectedCategory: null,
    limit: 0
  })
  const [discounts, setDiscounts] = useState<IDiscount[]>([])
  const [isOpenCollapseUser, setIsOpenCollapseUser] = useState(false)

  const onSetShowCaptchaSeatMap = (data: boolean) => {
    setShowCaptchaSeatMap(data)
  }
  const [timer, setTimer] = useState(() => {
    if (isClient) {
      const storedTimer = sessionStorage.getItem(
        CONSTANTS.SESSIONSTORAGE.SESSION_TIMER
      )
      return storedTimer ? JSON.parse(storedTimer) : []
    }
    return {}
  })

  const [alert, setAlert] = useState<TAlert>()
  const [alertFooter, setAlertFooter] = useState<TAlert>()

  const [globalLoading, setGlobalLoading] = useState<boolean>(false)

  const [cart, setCart] = useState<TCartData>(() => {
    if (typeof window !== 'undefined') {
      return {
        id: sessionStorage.getItem(CONSTANTS.COOKIE.CART_ID),
        sessionId: sessionStorage.getItem(CONSTANTS.COOKIE.CART_SESSION_ID),
        sectionId: sessionStorage.getItem(CONSTANTS.COOKIE.CART_SECTION_ID),
        info: null,
        hasSeatSelection: null
      }
    }
  })
  const [page, setPage] = useState<string>(() => {
    const page = cookies.get(CONSTANTS.PAGE.PAGE)
    return page
  })

  const [changeSeatMap, setIsChangeSeatMap] = useState<boolean>(false)
  const [sessionId, setSessionId] = useState<string>(() => {
    if (typeof window !== 'undefined') {
      return sessionStorage.getItem(CONSTANTS.COOKIE.CART_SESSION_ID)
    }
  })
  const [sessionData, setSessionData] = useState<TSessionData>(() => {
    if (typeof sessionStorage !== 'undefined' && sessionStorage !== null) {
      const sessionDataCurrent = JSON.parse(
        sessionStorage.getItem(CONSTANTS.SESSIONSTORAGE.SESSION_DATA) || '{}'
      )
      return sessionDataCurrent
    }
    return {}
  })
  const [tickets, setTickets] = useState<TTickets>({
    all: [],
    selected: sessionData?.selectedTickets ?? [],
    totalSelected: getTotalQuantityTickets(sessionData?.selectedTickets || []),
    limit: sessionData.hasSeatSelection
      ? sessionData.selectedSeats.length
      : cart?.info?.sessions?.[0]?.maxTickets || 8
  })
  const [paymentAsyncData, setPaymentAsyncData] = useState<TPaymentAsyncInfo>(
    () => {
      if (typeof window !== 'undefined') {
        const storedPaymentData = localStorage.getItem(
          CONSTANTS.PAYMENTSTORAGE.PAYMENT_DATA
        )
        return storedPaymentData ? JSON.parse(storedPaymentData) : []
      }
    }
  )
  const [associatedSeats, setAssociatedSeats] = useState<ISeatInfo[]>(() => {
    if (isClient) {
      return sessionData.selectedSeats
    }
  })
  const [seats, setSeats] = useState<TSeatsPageData>({
    selected: sessionData?.selectedSeats ?? [],
    showNumber: false
  })
  const [debitCardMethodPaymentOpen, setDebitCardMethodPaymentOpen] =
    useState<boolean>(false)
  const [creditCardMethodPaymentOpen, setCreditCardMethodPaymentOpen] =
    useState<boolean>(false)
  const [applePayMethodPaymentOpen, setApplePayMethodPaymentOpen] =
    useState<boolean>(false)
  const [googlePayMethodOpen, setGooglePayMethodOpen] = useState<boolean>(false)
  const [pixPayMethodOpen, setPixPayMethodOpen] = useState<boolean>(false)
  const [nuPayMethodOpen, setNuPayMethodOpen] = useState<boolean>(false)
  const [paymentMethodCurrent, setPaymentMethodCurrent] = useState<string>(() =>
    cookies.get('paymentmethodcurrent')
  )
  const { isMobileApp, onLoginSuccessfulButtonDidTap } = useWebView()

  const onSetDebitCardMethodPaymentOpen = useCallback((data: boolean) => {
    setDebitCardMethodPaymentOpen(data)
  }, [])
  const onSetCreditCardMethodPaymentOpen = useCallback((data: boolean) => {
    setCreditCardMethodPaymentOpen(data)
  }, [])
  const onSetApplePayMethodPaymentOpen = useCallback((data: boolean) => {
    setApplePayMethodPaymentOpen(data)
  }, [])
  const onSetGooglePayMethodOpen = useCallback((data: boolean) => {
    setGooglePayMethodOpen(data)
  }, [])
  const onSetPixPayMethodOpen = useCallback((data: boolean) => {
    setPixPayMethodOpen(data)
  }, [])
  const onSetNuPayMethodOpen = useCallback((data: boolean) => {
    setNuPayMethodOpen(data)
  }, [])
  const onSetPaymentMethodCurrent = useCallback((data: string) => {
    cookies.set('paymentmethodcurrent', data, {
      path: CONSTANTS.COOKIE.PATH,
      domain: getCurrentDomainCookie()
    })
    setPaymentMethodCurrent(data)
  }, [])
  const onSetAuthToken = (token: IAuthToken, isLogin?: boolean) => {
    const setCookieToken = (token: IAuthToken, expires?: any) => {
      const options = {
        path: CONSTANTS.COOKIE.PATH,
        domain: getCurrentDomainCookie(),
        ...(expires && { expires })
      }
      if (!isMobileApp())
        cookies.set(process.env.COOKIE_TOKEN_NAME_APP, token, options)
      else cookies.set(process.env.COOKIE_TOKEN_NAME, token, options)
    }

    if (token?.type === 'bearer') {
      let expirationToken = null
      token?.expires
        ? (expirationToken = new Date(token?.expires))
        : (expirationToken = new Date())

      expirationToken.setFullYear(expirationToken.getFullYear() + 1)
      setCookieToken(token, expirationToken)
    } else if (token?.type === 'Guest') {
      setCookieToken(token)
    }
    setAuthToken(token)
    // quando o token for setado pela tela de login
    if (isMobileApp() && isLogin) onLoginSuccessfulButtonDidTap(token)
  }
  const onSetDiscounts = (data: IDiscount) => {
    setDiscounts((prevDiscounts) => [...prevDiscounts, data])
  }

  const onSetModalChallenge = (data: boolean) => {
    setShowModalChallenge(data)
  }

  const onSetHasRunEffect = useCallback((data: boolean) => {
    setHasEffectRun(data)
  }, [])

  const onRemoveDiscounts = (
    password: string,
    isClubUolDiscount = false,
    idDiscount,
    removeAll = false
  ) => {
    if (removeAll) {
      setDiscounts([])
    } else {
      if (isClubUolDiscount) {
        const filteredDiscounts = discounts.filter(
          (discount) => discount.id !== idDiscount
        )
        sessionData.discounts = sessionData.discounts.filter(
          (discount) => discount.id !== idDiscount
        )
        onSetSessionData(sessionData)
        setDiscounts(filteredDiscounts)
      } else {
        const filteredDiscounts = discounts?.filter(
          (discount) => discount.password !== password
        )
        setDiscounts(filteredDiscounts)
      }
    }
  }
  const onSetIsOpenCollapseUser = (data: boolean) => {
    setIsOpenCollapseUser(data)
  }

  const onSetAssociatedSeats = (data) => {
    setAssociatedSeats(data)
  }
  const onSetTimer = useCallback((data: TTimer) => {
    const timer = {
      reservationThresholdWarning: data.reservationThresholdWarning
    }
    sessionStorage.setItem(
      CONSTANTS.SESSIONSTORAGE.SESSION_TIMER,
      JSON.stringify(timer)
    )
    setTimer(timer)
  }, [])

  const onSetCartIsReady = (data: boolean) => {
    setCartIsReady(data)
  }
  const onSetGlobalLoading = (data: boolean) => {
    setGlobalLoading(data)
  }
  const onSetModal = (data: TModal) => {
    setModal(data)
  }
  const onSetLoadingButton = (data: boolean) => {
    setIsLoadingButton(data)
  }
  const onSetLoading = (data: boolean) => {
    setIsLoading(data)
  }
  const onSetSessionId = useCallback(
    (number: string) => {
      setSessionId(number)
      cookies.set(CONSTANTS.COOKIE.CART_SESSION_ID, number, {
        path: CONSTANTS.COOKIE.PATH,
        domain: getCurrentDomainCookie()
      })
    },
    [sessionId]
  )
  const onSetAlert = useCallback(
    (data: TAlert) => {
      setAlert(data)
    },
    [alert]
  )
  const onSetAlertFooter = useCallback(
    (data: TAlert) => {
      setAlertFooter(data)
    },
    [alertFooter]
  )
  const onSetCart = useCallback((data: TCartData) => {
    let cart = null
    if (typeof window !== 'undefined') {
      cart = {
        id: sessionStorage.getItem(CONSTANTS.COOKIE.CART_ID) ?? data?.id,
        sessionId:
          sessionStorage.getItem(CONSTANTS.COOKIE.CART_SESSION_ID) ??
          data?.sessionId,
        sectionId:
          sessionStorage.getItem(CONSTANTS.COOKIE.CART_SECTION_ID) ??
          data?.sectionId,
        info: data?.info,
        hasSeatSelection: data?.hasSeatSelection
      }
    }
    setCart(cart)
  }, [])

  const onSetSessionData = (sessionDataUpdated: TSessionData) => {
    if (isClient) {
      try {
        sessionStorage.setItem(
          CONSTANTS.SESSIONSTORAGE.SESSION_DATA,
          JSON.stringify(sessionDataUpdated)
        )
        setSessionData(sessionDataUpdated)
      } catch (error) {
        console.error(error)
      }
    }
  }
  const onSetTickets = (data: TTickets) => {
    const updatedSessionData = {
      ...sessionData,
      selectedTickets: data?.selected
    }
    onSetSessionData(updatedSessionData)
    setTickets(data)
  }

  const onSetPaymentAsyncInfo = (paymentAsyncData: TPaymentAsyncInfo) => {
    localStorage.setItem(
      CONSTANTS.PAYMENTSTORAGE.PAYMENT_DATA,
      JSON.stringify(paymentAsyncData)
    )
    setPaymentAsyncData(paymentAsyncData)
  }

  const onSetBomboniere = (data: TBombonieres) => {
    const updatedSessionData = {
      ...sessionData,
      selectedBomboniere: data?.selected
    }
    onSetSessionData(updatedSessionData)
    setBomboniere(data)
  }

  const onSetIsChangeSeatMap = (data: boolean) => {
    setIsChangeSeatMap(data)
  }
  const onSetPage = (page: string) => {
    cookies.set(CONSTANTS.PAGE.PAGE, page, {
      path: CONSTANTS.COOKIE.PATH,
      domain: getCurrentDomainCookie()
    })
    setPage(page)
  }

  const onSetSeats = (data: TSeatsPageData) => {
    const selected = []
    selected.push(...data.selected)
    setSeats({ selected })
  }

  const [fidelity, setFidelity] = useState<boolean>(() => {
    return typeof cookies.get(CONSTANTS.COOKIE.IS_FIDELITY) === 'string'
      ? JSON.parse(cookies.get(CONSTANTS.COOKIE.IS_FIDELITY))
      : false
  })
  const [sameCpfFidelity, setSameCpfFidelity] = useState<boolean>(() => {
    return typeof cookies.get(CONSTANTS.COOKIE.IS_SAMECPF_FIDELITY) === 'string'
      ? JSON.parse(cookies.get(CONSTANTS.COOKIE.IS_SAMECPF_FIDELITY))
      : false
  })
  const [fidelityFields, setFidelityFields] = useState<IFidelity[]>(() => {
    if (typeof window !== 'undefined') {
      return sessionData.fidelityFields ?? []
    }
  })

  const onSetFidelity = (data: boolean) => {
    cookies.set(CONSTANTS.COOKIE.IS_FIDELITY, data, {
      path: CONSTANTS.COOKIE.PATH,
      domain: getCurrentDomainCookie()
    })
    setFidelity(data)
  }
  const onSetSameCpfFidelity = (value) => {
    cookies.set(CONSTANTS.COOKIE.IS_SAMECPF_FIDELITY, value, {
      path: CONSTANTS.COOKIE.PATH,
      domain: getCurrentDomainCookie()
    })
    setSameCpfFidelity(value)
  }
  const onSetFidelityFields = (fields: any) => {
    setFidelityFields(fields)
    sessionData.fidelityFields = fields
  }

  return (
    <GlobalContext.Provider
      value={{
        uolAds,
        setUolAds,
        authToken,
        onSetAuthToken,
        onSetGlobalLoading,
        globalLoading,
        modal,
        onSetModal,
        alert,
        onSetAlert,
        discounts,
        onSetDiscounts,
        onRemoveDiscounts,
        onSetCartIsReady,
        onSetAssociatedSeats,
        associatedSeats,
        isOpenCollapseUser,
        onSetIsOpenCollapseUser,
        cartIsReady,
        isLoading,
        onSetLoading,
        isLoadingButton,
        onSetLoadingButton,
        cart,
        onSetCart,
        onSetSessionData,
        sessionData,
        sessionId,
        onSetSessionId,
        page,
        onSetPage,
        seats,
        onSetSeats,
        timer,
        onSetTimer,
        tickets,
        onSetTickets,
        bomboniere,
        onSetBomboniere,
        changeSeatMap,
        onSetIsChangeSeatMap,
        fidelity,
        onSetFidelity,
        debitCardMethodPaymentOpen,
        creditCardMethodPaymentOpen,
        onSetCreditCardMethodPaymentOpen,
        onSetDebitCardMethodPaymentOpen,
        applePayMethodPaymentOpen,
        onSetApplePayMethodPaymentOpen,
        pixPayMethodOpen,
        onSetPixPayMethodOpen,
        nuPayMethodOpen,
        onSetNuPayMethodOpen,
        onSetPaymentMethodCurrent,
        paymentMethodCurrent,
        googlePayMethodOpen,
        onSetGooglePayMethodOpen,
        alertFooter,
        onSetAlertFooter,
        onSetFidelityFields,
        fidelityFields,
        onSetSameCpfFidelity,
        onSetModalChallenge,
        showModalChallenge,
        onSetHasRunEffect,
        hasEffectRun,
        sameCpfFidelity,
        onSetPaymentAsyncInfo,
        paymentAsyncData,
        onSetShowCaptchaSeatMap,
        showCaptchaSeatMap
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
