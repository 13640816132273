export const cpfMask = (value: string, previousValue: string) => {
  if (!value) {
    return value
  }
  const onlyNums = value?.replace(/[^\d]/g, '')
  if (!previousValue || value.length > previousValue.length) {
    if (onlyNums.length === 3) {
      return `${onlyNums}.`
    }
    if (onlyNums.length === 6) {
      return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3)}.`
    }
    if (onlyNums.length === 9) {
      return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
        6,
        9
      )}-`
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3)}`
  }
  if (onlyNums.length <= 9) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
      6,
      9
    )}`
  }
  return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
    6,
    9
  )}-${onlyNums.slice(9, 11)}`
}
export const validarCPF = (inputCPF) => {
  const onlyNumbersCpf = inputCPF?.replace(/[^\d]+/g, '')

  if (onlyNumbersCpf?.length !== 11 || /^(\d)\1+$/.test(onlyNumbersCpf)) {
    return false
  }

  let soma = 0
  let resto

  if (onlyNumbersCpf === '00000000000') {
    return false
  }

  for (let i = 1; i <= 9; i++) {
    soma = soma + parseInt(onlyNumbersCpf?.substring(i - 1, i)) * (11 - i)
  }
  resto = (soma * 10) % 11

  if (resto === 10 || resto === 11) {
    resto = 0
  }
  if (resto !== parseInt(onlyNumbersCpf?.substring(9, 10))) {
    return false
  }

  soma = 0
  for (let i = 1; i <= 10; i++) {
    soma = soma + parseInt(onlyNumbersCpf?.substring(i - 1, i)) * (12 - i)
  }
  resto = (soma * 10) % 11

  if (resto === 10 || resto === 11) {
    resto = 0
  }
  if (resto !== parseInt(onlyNumbersCpf?.substring(10, 11))) {
    return false
  }

  return true
}
