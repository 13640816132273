import { IPolicy } from '@Components/Login/interface'
import { initialSteps } from '@Components/Login/steps'
import React, { createContext, useState } from 'react'

const LoginContext = createContext(null)
interface ILoginModal {
  show: boolean
  onNextPage?: boolean
}
const LoginProvider = ({ children }) => {
  const [loginSteps, setLoginSteps] = useState(initialSteps)
  const [loginStepPage, setLoginStepPage] = useState(1)
  const [loginCurrentStepNumber, setLoginCurrentStepNumber] = useState(1)
  const [emailOrCpfValueContext, setEmailOrCpfValueContext] = useState('')
  const [openLogin, setIsOpenlogin] = useState<ILoginModal>({ show: false })
  const [isFromLoginAccount, setIsFromLoginAccount] = useState<boolean>(false)
  const [dataFromCreateAccountStepOne, setDataCreateAccountStepOne] = useState<
    string[]
  >([''])
  const [policiesFromContext, setPoliciesContext] = useState<IPolicy[]>()
  const [openVerifyCode, setOpenVerifyCode] = useState<boolean>(false)
  const [verifyCodeValue, setVerifyCodeValue] = useState<boolean>(false)
  const [hasAccount, setHasAccount] = useState<boolean>(false)
  const [errorDescriptionToken, setErrorDescriptionToken] = useState<string>('')

  const [loginCurrentStep, setLoginCurrentStep] = useState(() => {
    const currentStep = loginSteps.find(
      (item) =>
        item.step === loginCurrentStepNumber && item.stepPage === loginStepPage
    )
    return { ...currentStep }
  })

  const onSetLoginSteps = (steps) => {
    setLoginSteps(steps)
  }
  const onSetIsFromLoginAccount = (data: boolean) => {
    setIsFromLoginAccount(data)
  }
  const onSetIsOpenLogin = (data: ILoginModal) => {
    setIsOpenlogin(data)
  }
  const onSetCurrentLoginStepNumberAndPage = (stepNumber, stepPageNumber) => {
    const currentStep = loginSteps.find(
      (item) => item.step === stepNumber && item.stepPage === stepPageNumber
    )
    setLoginCurrentStep(currentStep)
    setLoginCurrentStepNumber(stepNumber)
    setLoginStepPage(stepPageNumber)
  }

  const contextValue = {
    loginSteps,
    onSetLoginSteps,
    loginStepPage,
    onSetCurrentLoginStepNumberAndPage,
    loginCurrentStep,
    emailOrCpfValueContext,
    setEmailOrCpfValueContext,
    openLogin,
    onSetIsOpenLogin,
    isFromLoginAccount,
    onSetIsFromLoginAccount,
    setDataCreateAccountStepOne,
    dataFromCreateAccountStepOne,
    policiesFromContext,
    setPoliciesContext,
    openVerifyCode,
    setOpenVerifyCode,
    verifyCodeValue,
    setVerifyCodeValue,
    errorDescriptionToken,
    setErrorDescriptionToken,
    setHasAccount,
    hasAccount
  }

  return (
    <LoginContext.Provider value={contextValue}>
      {children}
    </LoginContext.Provider>
  )
}

export { LoginContext, LoginProvider }
