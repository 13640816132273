export const PATH = '/'

export const CART_ID = 'NewCheckoutCartId'
export const CART_SESSION_ID = 'NewCheckoutSessionId'
export const CART_SECTION_ID = 'NewCheckoutSectionId'

export const CURRENT_CITY = 'CurrentCity'
export const SITE_CITY = 'SiteCity'

export const USER_ID = 'userId'

export const TRACKING_PAGE_INFO = 'trackingPageInfo'

export const PAGE = 'page'
export const IS_FIDELITY = 'isFidelity'
export const IS_SAMECPF_FIDELITY = 'isSameCpfFidelity'
