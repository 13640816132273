import { IAuthToken } from '@shared/context/interface'
import useMediaQuery from './useMediaQuery'
import { useCookies } from './useCookies'
import { useRouter } from 'next/router'

export function useWebView() {
  const isMobileSizeScreen = useMediaQuery('(max-width: 991px)')
  const router = useRouter()
  const queryFromApp = (router?.query?.app || router?.query?.APP) as string
  const { removeCookie } = useCookies()
  const isIOSRegex = /TicketingAppIOS|SuitMobileAppIOS/i
  const isAndroidRegex = /TicketingAppAndroid|SuitMobileAppAndroid/i
  const isSuitMobileRegex = /SuitMobileAppIOS|SuitMobileAppAndroid/i

  const isAndroid = () => {
    return isAndroidRegex.test(queryFromApp)
  }

  const isIOS = () => {
    return isIOSRegex.test(queryFromApp)
  }

  const isSuitMobile = () => {
    return isSuitMobileRegex.test(queryFromApp)
  }

  const isMobileApp = () => {
    const isMobile =
      isAndroidRegex.test(queryFromApp) || isIOSRegex.test(queryFromApp)
    return isMobile
  }
  const getClientOrigin = () => {
    if (typeof window === 'undefined') return 'Site'

    if (typeof window !== 'undefined') {
      const userAgent = window.navigator.userAgent.toLowerCase()
      const isMobileDevice = /mobile|android|iphone|ipad|tablet/i.test(
        userAgent
      )

      if (isMobileApp()) {
        return queryFromApp
      } else if (isMobileDevice || isMobileSizeScreen) {
        return 'Site Mobile'
      } else {
        return 'Site'
      }
    }
  }
  const cartIsReadyWebView = () => {
    try {
      if (isAndroid()) window.TicketingAppAndroid.onCartReady()
      if (isIOS())
        window.webkit.messageHandlers.callbackHandler.postMessage({
          onCartReady: ''
        })
    } catch (error) {
      console.warn('error in function cartIsReadyWebView', error)
    }
  }
  const goToConfirmed = (order: unknown) => {
    // Router gerencia somente URLs internas, tentando redirecionar via $window
    console.debug('Sending order to mobile app.')
    try {
      if (isIOS())
        window.webkit.messageHandlers.callbackHandler.postMessage({
          checkoutWasSuccessful: order
        })
      else if (isAndroid())
        window.TicketingAppAndroid.checkoutSuccessfulWasCalled(
          JSON.stringify(order)
        )
    } catch (err) {
      console.error(err)
    }
  }
  const onLoginSuccessfulButtonDidTap = (token: IAuthToken) => {
    const expirationToken = token?.expires

    try {
      const loginSuccessful = {
        access_token: token.access_token,
        token_type: token.type,
        expires_in: token.expires_in,
        refresh_token: token.refresh_token,
        user_id: token.user_id,
        user_first_name: token.user_first_name,
        client_origin: token.client_origin,
        user_email: token.email,
        '.issued': token.issued,
        '.expires': expirationToken,
        has_apple_pay: token?.has_apple_pay,
        has_apple_pay_configured: token?.has_apple_pay_configured,
        device_id: token?.device_id,
        app_version: token?.app_version
      }
      if (isIOS())
        window.webkit.messageHandlers.callbackHandler.postMessage({
          loginSuccessful: loginSuccessful
        })
      else if (isAndroid())
        window.TicketingAppAndroid.loginSuccessfulWasCalled(
          JSON.stringify(loginSuccessful)
        )
    } catch (err) {
      console.error(err)
    }
  }

  const closeCheckout = () => {
    if (isMobileApp()) removeCookie(process.env.COOKIE_TOKEN_NAME)
    try {
      if (isAndroid()) window.TicketingAppAndroid.closeCheckoutWasCalled()
      if (isIOS())
        window.webkit.messageHandlers.callbackHandler.postMessage({
          closeCheckout: ''
        })
    } catch (err) {
      console.error(err)
    }
  }

  const onRequestAuthorizationToApplePay = (orderRequest) => {
    if (isMobileApp()) {
      console.debug('Sending order to mobile app.', orderRequest)
      if (isIOS())
        try {
          window.webkit.messageHandlers.callbackHandler.postMessage({
            requestAuthorizationToApplePay: orderRequest
          })
        } catch (err) {
          console.error(err)
        }
    }
  }
  const checkoutApplePayWasError = (error) => {
    if (isMobileApp()) {
      if (isIOS())
        try {
          window.webkit.messageHandlers.callbackHandler.postMessage({
            checkoutApplePayWasError: error
          })
        } catch (err) {
          console.log('error')
        }
    }
  }

  return {
    isMobileApp,
    cartIsReadyWebView,
    isAndroid,
    isIOS,
    getClientOrigin,
    goToConfirmed,
    onLoginSuccessfulButtonDidTap,
    closeCheckout,
    onRequestAuthorizationToApplePay,
    checkoutApplePayWasError,
    isSuitMobile
  }
}
