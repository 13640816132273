import * as HOST from './host'
import * as URL from './url'
import * as COOKIE from './cookie'
import * as PAGE from './page'
import * as SESSIONSTORAGE from './sessionStorage'
import * as PAYMENTSTORAGE from './paymentStorage'

const CONSTANTS = {
  HOST,
  URL,
  COOKIE,
  PAGE,
  SESSIONSTORAGE,
  PAYMENTSTORAGE
}

export default CONSTANTS
