import { validarCPF } from '@Helpers/CPF'
import { TLoguinProps } from './interface'

export const initialSteps: TLoguinProps[] = [
  {
    stepPage: 1,
    step: 1,
    title: 'Insira seu CPF ou e-mail para continuar a compra',
    button: {
      label: 'Continuar',
      height: '48px'
    },
    social: false,
    benefits: false,
    policy: false,
    inputs: [
      {
        id: 1,
        label: '*CPF ou e-mail',
        value: '',
        valid: (value) => {
          const emailRegex = /\S+@\S+\.\S+/
          return emailRegex.test(value) || validarCPF(value)
        },
        validation: {
          required: {
            value: true,
            message: 'É obrigatório informar o CPF ou e-mail.'
          },
          validate: (value) => {
            const emailRegex = /\S+@\S+\.\S+/
            return (
              emailRegex.test(value) ||
              validarCPF(value) ||
              'Digite um cpf ou e-mail válido.'
            )
          }
        },
        // mask: '999.999.999-99',
        applyMaskOnlyThisConditionIsTrue: (value) => {
          return /^\d/.test(value)
        },
        typeStopInteractionSuccess: 'success',
        lowerCase: true
      }
    ]
  },
  {
    stepPage: 1,
    step: 2,
    title: 'Faça login com sua conta Ingresso ou entre sem cadastro',
    button: {
      label: 'ENTRAR COMO CLIENTE',
      icon: 'logo',
      height: '48px',
      isInitialPositionIcon: true
    },
    helpRedirect: 'Problemas para entrar ?',
    otherButton: {
      title: 'ou',
      label: 'ENTRAR SEM CADASTRO',
      variant: 'outlined'
    },
    social: false,
    benefits: true,
    policy: false,
    inputs: [
      {
        id: 1,
        label: '*CPF ou e-mail',
        value: '',
        valid: (value) => {
          const emailRegex = /\S+@\S+\.\S+/
          return emailRegex.test(value) || validarCPF(value)
        },
        typeStopInteractionSuccess: 'success',
        hasErrorActive: false,
        inputName: 'emailOrCpf',
        mask: '999.999.999-99',
        applyMaskOnlyThisConditionIsTrue: (value) => {
          return /^\d/.test(value)
        },
        change: 'Trocar'
      },
      {
        id: 2,
        label: '*Senha',
        value: '',
        pass: true,
        valid: (value) => {
          return value.length > 0
        },
        inputName: 'password',
        typeStopInteractionSuccess: 'success',
        validation: {
          required: {
            value: true,
            message: 'O campo senha é obrigatório.'
          }
        }
      }
    ],
    error: true,
    errorMessage: ''
  },
  {
    stepPage: 2,
    step: 2,
    title: 'Você ainda não tem conta. Entre sem cadastro ou crie uma conta',
    button: {
      label: 'CRIAR UMA CONTA',
      icon: 'logo',
      height: '48px',
      isInitialPositionIcon: true
    },
    otherButton: {
      title: 'ou',
      label: 'ENTRAR SEM CADASTRO',
      variant: 'outlined'
    },
    social: false,
    benefits: true,
    policy: false,
    inputs: [
      {
        id: 1,
        label: '*CPF ou e-mail',
        value: '',
        change: 'Trocar',
        validation: {
          required: {
            value: false
          }
        }
      }
    ]
  },
  {
    stepPage: 2,
    step: 3,
    title: 'Entre sem cadastro',
    button: {
      label: 'ENTRAR SEM CADASTRO'
    },
    social: false,
    benefits: true,
    policy: false,
    inputs: [
      {
        id: 1,
        label: 'Email',
        value: '',
        hasErrorActive: false,
        change: 'Trocar',
        typeStopInteraction: 'error',
        typeStopInteractionSuccess: 'success',
        inputName: 'emailOrCpf',
        valid: (value) => {
          const emailRegex = /\S+@\S+\.\S+/
          return validarCPF(value) || emailRegex.test(value)
        },
        validation: {
          required: {
            value: false
          }
        }
      },
      {
        id: 2,
        label: 'E-mail',
        value: '',
        valid: (value) => {
          const emailRegex = /\S+@\S+\.\S+/
          return emailRegex.test(value)
        },
        hasErrorActive: false,
        inputName: 'email',
        typeStopInteraction: 'error',
        typeStopInteractionSuccess: 'success',
        errorMessage: '',
        validation: {
          required: {
            value: true,
            message: 'É obrigatório informar o  e-mail.'
          },
          validate: (value) => {
            const emailRegex = /\S+@\S+\.\S+/
            return emailRegex.test(value) || 'Digite um  e-mail válido.'
          }
        }
      },
      {
        id: 3,
        label: '*Confirmar e-mail',
        value: '',
        typeStopInteraction: 'error',
        typeStopInteractionSuccess: 'success',
        hasErrorActive: false,
        inputName: 'email2',
        // mask: '999.999.999-99',
        applyMaskOnlyThisConditionIsTrue: (value) => {
          return /^\d/.test(value)
        },
        valid: (value) => {
          const emailRegex = /\S+@\S+\.\S+/
          return validarCPF(value) || emailRegex.test(value)
        },
        validation: {
          required: {
            value: true,
            message: 'Confirme seu e-mail.'
          },
          validate: (value) => {
            const emailRegex = /\S+@\S+\.\S+/
            return emailRegex.test(value) || 'Digite um  e-mail válido.'
          }
        }
      }
    ]
  },
  {
    stepPage: 3,
    step: 2,
    title: 'Você ainda não tem conta. Entre sem cadastro ou crie uma conta',
    button: {
      label: 'ENTRAR SEM CADASTRO'
    },
    otherButton: {
      title: 'ou',
      label: 'CRIAR UMA CONTA',
      variant: 'default'
    },
    social: false,
    benefits: true,
    policy: false,
    inputs: [
      {
        id: 1,
        label: '*CPF ou e-mail',
        value: '',
        hasErrorActive: false
      }
    ]
  },
  {
    stepPage: 1,
    step: 3,
    title: 'Problemas para entrar',
    SubTitle: 'Digite seu cpf ou e-mail e selecione o que aconteceu com você.',
    button: {
      label: 'CONFIRMAR'
    },
    social: false,
    benefits: true,
    policy: false,
    inputs: [
      {
        id: 1,
        label: '*Digite seu cpf ou e-mail',
        placeholder: '*Digite seu cpf ou e-mail',
        value: '',
        valid: (value) => {
          const emailRegex = /\S+@\S+\.\S+/
          return emailRegex.test(value) || validarCPF(value)
        },
        validation: {
          required: {
            value: true,
            message: 'É obrigatório informar o Cpf ou e-mail.'
          },
          validate: (value) => {
            const emailRegex = /\S+@\S+\.\S+/
            return (
              emailRegex.test(value) ||
              validarCPF(value) ||
              'Cpf ou e-mail inválido.'
            )
          }
        },
        inputName: 'cpfOrEmail',
        typeStopInteractionSuccess: 'success',
        hasErrorActive: false
      }
    ],
    doubts: true,
    radio: [
      {
        id: 1,
        label: 'Esqueci minha senha',
        value: '',
        name: 'op'
      }
    ]
  },
  {
    stepPage: 3,
    step: 3,
    title: 'Crie uma conta em 2 passos',
    button: {
      label: 'CONTINUAR'
    },
    social: false,
    benefits: true,
    policy: true,
    inputs: [
      {
        id: 1,
        label: 'CPF',
        value: '',
        change: 'Trocar',
        hasErrorActive: false,
        inputName: 'cpf',
        typeStopInteraction: 'error',
        typeStopInteractionSuccess: 'success',
        valid: (value) => {
          return validarCPF(value)
        },
        mask: '999.999.999-99',
        applyMaskOnlyThisConditionIsTrue: () => {
          return true
        }
      },
      {
        id: 2,
        label: '*Nome',
        value: '',
        typeStopInteractionSuccess: 'success',
        inputName: 'name',
        valid: (value) => {
          const rgxNome = /\W/
          return (
            !rgxNome.test(value.replaceAll(' ', '')) &&
            value.length >= 3 &&
            value.length <= 60
          )
        },
        hasErrorActive: false,
        errorMessage:
          'O nome precisa ter entre 3 e 60 caracteres e não possuir caracteres especiais'
      },
      {
        id: 3,
        label: '*Celular',
        value: '',
        typeStopInteractionSuccess: 'success',
        hasErrorActive: false,
        inputName: 'phone',
        mask: '(99) 99999-9999',
        applyMaskOnlyThisConditionIsTrue: () => {
          return true
        },
        valid: (value) => {
          const digitValue = value.replace(/\D/g, '') // Remove caracteres = '_' do InputMask
          return digitValue.length === 11
        },
        validation: {
          required: {
            value: true,
            message: 'O campo celular é obrigatório.'
          },
          validate: (value) => {
            const digitValue = value.replace(/\D/g, '') // Remove caracteres = '_' do InputMask
            return digitValue.length === 11 || 'Insira um número válido.'
          }
        },
        errorMessage: 'O campo Celular está invalido'
      }
    ],
    error: false
  },
  {
    stepPage: 3,
    step: 4,
    createAccount: true,
    title: 'Crie uma conta em 2 passos',
    button: {
      label: 'Criar Conta'
    },
    social: false,
    benefits: true,
    policy: true,
    inputs: [
      {
        id: 1,
        label: 'CPF',
        value: '',
        change: 'Trocar',
        inputName: 'emailOrCpf',
        hasErrorActive: false,
        typeStopInteractionSuccess: 'success',
        valid: (value) => {
          return validarCPF(value)
        }
      },
      {
        id: 2,
        label: 'E-mail',
        placeholder: 'E-mail',
        value: '',
        valid: (value) => {
          const emailRegex = /\S+@\S+\.\S+/
          return emailRegex.test(value)
        },
        hasErrorActive: false,
        inputName: 'email',
        typeStopInteraction: 'error',
        typeStopInteractionSuccess: 'success',
        errorMessage: '',
        validation: {
          required: {
            value: true,
            message: 'Preencha o campo de  e-mail.'
          },
          validate: (value) => {
            const emailRegex = /\S+@\S+\.\S+/
            return emailRegex.test(value) || 'Digite um  e-mail válido.'
          }
        },
        applyMaskOnlyThisConditionIsTrue: () => {
          return false
        },
        onPaste: (e) => {
          e.preventDefault()
          return false
        }
      },
      {
        id: 3,
        label: 'Confirmar e-mail',
        placeholder: 'Confirmar e-mail',
        value: '',
        valid: (value) => {
          const emailRegex = /\S+@\S+\.\S+/
          return emailRegex.test(value)
        },
        hasErrorActive: false,
        inputName: 'email2',
        typeStopInteraction: 'error',
        typeStopInteractionSuccess: 'success',
        errorMessage: '',
        validation: {
          required: {
            value: true,
            message: 'Confirme seu e-mail.'
          },
          validate: (value) => {
            const emailRegex = /\S+@\S+\.\S+/
            return emailRegex.test(value) || 'Digite um  e-mail válido.'
          }
        },
        onPaste: (e) => {
          e.preventDefault()
          return false
        }
      },
      {
        id: 4,
        label: '*Senha',
        pass: true,
        hasErrorActive: false,
        inputName: 'password',
        valid: (value) => {
          const validations = [
            { name: 'Mínimo uma letra maiúscula*', regex: /[A-Z]/ },
            { name: 'Mínimo uma letra minúscula*', regex: /[a-z]/ },
            { name: 'Mínimo um número*', regex: /[0-9]/ },
            { name: 'Mínimo de 8 caracteres*', regex: /.{8,}/ }
          ]

          for (const validation of validations) {
            if (!validation.regex.test(value)) {
              return false
            }
          }

          return true
        },
        validation: {
          required: {
            value: true,
            message: 'O campo senha é obrigatório.'
          },
          validate: (value) => {
            const validations = [
              { name: 'Mínimo uma letra maiúscula*', regex: /[A-Z]/ },
              { name: 'Mínimo uma letra minúscula*', regex: /[a-z]/ },
              { name: 'Mínimo um número*', regex: /[0-9]/ },
              { name: 'Mínimo de 8 caracteres*', regex: /.{8,}/ }
            ]

            for (const validation of validations) {
              if (!validation.regex.test(value)) {
                return 'A senha não atende aos critérios necessários.'
              }
            }

            return true
          }
        },
        value: '',
        helpText: 'Sua senha precisa atender aos seguintes critérios:',
        validationsPassword: [
          {
            name: 'Mínimo uma letra maiúscula*',
            valid: (str) => /[A-Z]/?.test(str)
          },
          {
            name: 'Mínimo uma letra minúscula *',
            valid: (str) => /[a-z]/?.test(str)
          },
          { name: 'Mínimo um número*', valid: (str) => /[0-9]/?.test(str) },
          { name: 'Mínimo de 8 caracteres*', valid: (str) => str?.length >= 8 }
        ]
      }
    ],
    errorMessage: 'teste',
    error: false
  }
]
export const inputsLoginEmailGuest = [
  {
    id: 1,
    label: 'E-mail',
    value: '',
    hasErrorActive: false,
    change: 'Trocar',
    typeStopInteraction: 'error',
    typeStopInteractionSuccess: 'success',
    inputName: 'emailOrCpf',
    lowerCase: true,
    valid: (value) => {
      const emailRegex = /\S+@\S+\.\S+/
      return validarCPF(value) || emailRegex.test(value)
    },
    validation: {
      required: {
        value: false
      }
    }
  },
  {
    id: 2,
    label: 'Confirmar e-mail',
    placeholder: 'Confirmar e-mail',
    value: '',
    lowerCase: true,
    valid: (value) => {
      const emailRegex = /\S+@\S+\.\S+/
      return emailRegex.test(value)
    },
    hasErrorActive: false,
    inputName: 'email2',
    typeStopInteraction: 'error',
    typeStopInteractionSuccess: 'success',
    errorMessage: '',
    validation: {
      required: {
        value: true,
        message: 'Confirme seu e-mail.'
      },
      validate: (value) => {
        const emailRegex = /\S+@\S+\.\S+/
        return emailRegex.test(value) || 'Digite um  e-mail válido.'
      }
    },
    onPaste: (e) => {
      e.preventDefault()
      return false
    }
  },
  {
    id: 3,
    label: '*CPF',
    value: '',
    typeStopInteraction: 'error',
    typeStopInteractionSuccess: 'success',
    placeholder: 'Digite seu CPF',
    inputName: 'cpf',
    mask: '999.999.999-99',
    applyMaskOnlyThisConditionIsTrue: () => {
      return true
    },
    valid: (value) => {
      return validarCPF(value)
    },
    validation: {
      required: {
        value: true,
        message: 'Preencha o campo de CPF.'
      },
      validate: (value) => {
        return validarCPF(value) || 'Digite um CPF válido.'
      }
    }
  }
]
export const inputsLoginCpfGuest = [
  {
    id: 1,
    label: 'CPF',
    value: '',
    hasErrorActive: false,
    change: 'Trocar',
    typeStopInteraction: 'error',
    typeStopInteractionSuccess: 'success',
    inputName: 'emailOrCpf',
    valid: (value) => {
      const emailRegex = /\S+@\S+\.\S+/
      return validarCPF(value) || emailRegex.test(value)
    },
    validation: {
      required: {
        value: false
      }
    }
  },
  {
    id: 2,
    label: 'E-mail',
    placeholder: 'E-mail',
    lowerCase: true,
    value: '',
    valid: (value) => {
      const emailRegex = /\S+@\S+\.\S+/
      return emailRegex.test(value)
    },
    hasErrorActive: false,
    inputName: 'email',
    typeStopInteraction: 'error',
    typeStopInteractionSuccess: 'success',
    errorMessage: '',
    validation: {
      required: {
        value: true,
        message: 'Preencha o campo de  e-mail.'
      },
      validate: (value) => {
        const emailRegex = /\S+@\S+\.\S+/
        return emailRegex.test(value) || 'Digite um  e-mail válido.'
      }
    },
    applyMaskOnlyThisConditionIsTrue: () => {
      return false
    },
    onPaste: (e) => {
      e.preventDefault()
      return false
    }
  },
  {
    id: 3,
    label: 'Confirmar e-mail',
    value: '',
    lowerCase: true,
    typeStopInteraction: 'error',
    typeStopInteractionSuccess: 'success',
    placeholder: 'Confirmar e-mail',
    inputName: 'email2',
    valid: (value) => {
      const emailRegex = /\S+@\S+\.\S+/
      return emailRegex.test(value)
    },
    validation: {
      required: {
        value: true,
        message: 'Confirme seu e-mail.'
      },
      validate: (value) => {
        const emailRegex = /\S+@\S+\.\S+/
        return emailRegex.test(value) || 'Digite um  e-mail válido.'
      }
    },
    onPaste: (e) => {
      e.preventDefault()
      return false
    }
  }
]

export const inputsLoginSuitMobile = [
  {
    id: 1,
    label: 'CPF',
    value: '',
    hasErrorActive: false,
    change: '',
    typeStopInteraction: 'error',
    typeStopInteractionSuccess: 'success',
    mask: '999.999.999-99',
    inputName: 'emailOrCpf',
    placeholder: 'CPF',
    applyMaskOnlyThisConditionIsTrue: () => {
      return true
    },
    valid: (value) => {
      const emailRegex = /\S+@\S+\.\S+/
      return validarCPF(value) || emailRegex.test(value)
    },
    validation: {
      required: {
        value: true,
        message: 'É obrigatório informar o CPF.'
      },
      validate: (value) => {
        return validarCPF(value) || 'Digite um cpf válido.'
      }
    }
  },
  {
    id: 2,
    label: 'E-mail',
    placeholder: 'E-mail',
    lowerCase: true,
    value: '',
    valid: (value) => {
      const emailRegex = /\S+@\S+\.\S+/
      return emailRegex.test(value)
    },
    hasErrorActive: false,
    inputName: 'email',
    typeStopInteraction: 'error',
    typeStopInteractionSuccess: 'success',
    errorMessage: '',
    validation: {
      required: {
        value: true,
        message: 'Preencha o campo de  e-mail.'
      },
      validate: (value) => {
        const emailRegex = /\S+@\S+\.\S+/
        return emailRegex.test(value) || 'Digite um  e-mail válido.'
      }
    },
    applyMaskOnlyThisConditionIsTrue: () => {
      return false
    },
    onPaste: (e) => {
      e.preventDefault()
      return false
    }
  },
  {
    id: 3,
    label: 'Confirmar e-mail',
    value: '',
    lowerCase: true,
    typeStopInteraction: 'error',
    typeStopInteractionSuccess: 'success',
    placeholder: 'Confirmar e-mail',
    inputName: 'email2',
    valid: (value) => {
      const emailRegex = /\S+@\S+\.\S+/
      return emailRegex.test(value)
    },
    validation: {
      required: {
        value: true,
        message: 'Confirme seu e-mail.'
      },
      validate: (value) => {
        const emailRegex = /\S+@\S+\.\S+/
        return emailRegex.test(value) || 'Digite um  e-mail válido.'
      }
    },
    onPaste: (e) => {
      e.preventDefault()
      return false
    }
  }
]
export const inputsLoginCreateAccountEmailStepOne = [
  {
    id: 1,
    label: 'CPF',
    value: '',
    change: 'Trocar',
    hasErrorActive: false,
    inputName: 'emailOrCpf',
    applyMaskOnlyThisConditionIsTrue: () => {
      return false
    }
  },
  {
    id: 2,
    label: '*Nome',
    value: '',
    typeStopInteractionSuccess: 'success',
    inputName: 'name',
    placeholder: '*Nome',
    valid: (value) => {
      const rgxNome = /\W/
      return (
        !rgxNome.test(value.replaceAll(' ', '')) &&
        value.length >= 3 &&
        value.length <= 60
      )
    },
    validation: {
      required: {
        value: true,
        message: 'O campo nome é obrigatório.'
      },
      validate: (value) => {
        const rgxNome = /\W/

        return (
          (!rgxNome.test(value.replaceAll(' ', '')) &&
            value.length >= 3 &&
            value.length <= 60) ||
          'O nome precisa ter entre 3 e 60 caracteres e não possuir caracteres especiais'
        )
      }
    },
    hasErrorActive: false
  },
  {
    id: 3,
    label: '*CPF',
    value: '',
    inputName: 'cpf',
    placeholder: '*CPF',
    hasErrorActive: false,
    typeStopInteractionSuccess: 'success',
    mask: '999.999.999-99',
    applyMaskOnlyThisConditionIsTrue: () => {
      return true
    },
    valid: (value) => {
      return validarCPF(value)
    },
    validation: {
      required: {
        value: true,
        message: 'O campo CPF é obrigatório'
      },
      validate: (value) => {
        return validarCPF(value) || 'CPF inválido.'
      }
    }
  },
  {
    id: 4,
    label: '*Celular',
    value: '',
    typeStopInteractionSuccess: 'success',
    hasErrorActive: false,
    inputName: 'phone',
    mask: '(99) 99999-9999',
    placeholder: '*Celular',
    applyMaskOnlyThisConditionIsTrue: () => {
      return true
    },
    valid: (value) => {
      const digitValue = value.replace(/\D/g, '') // Remove caracteres = '_' do InputMask
      return digitValue.length === 11
    },
    validation: {
      required: {
        value: true,
        message: 'O campo celular é obrigatório.'
      },
      validate: (value) => {
        const digitValue = value.replace(/\D/g, '') // Remove caracteres = '_' do InputMask
        return digitValue.length === 11 || 'Insira um número válido.'
      }
    }
  }
]

export const inputsLoginCreateAccountCpfStepOne = [
  {
    id: 1,
    label: 'E-mail',
    value: '',
    hasErrorActive: false,
    change: 'Trocar',
    typeStopInteraction: 'error',
    typeStopInteractionSuccess: 'success',
    inputName: 'emailOrCpf',
    valid: (value) => {
      const emailRegex = /\S+@\S+\.\S+/
      return validarCPF(value) || emailRegex.test(value)
    },
    validation: {
      required: {
        value: false
      }
    }
  },
  {
    id: 2,
    label: '*Nome',
    value: '',
    typeStopInteractionSuccess: 'success',
    inputName: 'name',
    placeholder: '*Nome',
    valid: (value) => {
      const rgxNome = /\W/
      return (
        !rgxNome.test(value.replaceAll(' ', '')) &&
        value.length >= 3 &&
        value.length <= 60
      )
    },
    validation: {
      required: {
        value: true,
        message: 'O campo nome é obrigatório.'
      },
      validate: (value) => {
        const rgxNome = /\W/

        return (
          (!rgxNome.test(value.replaceAll(' ', '')) &&
            value.length >= 3 &&
            value.length <= 60) ||
          'O nome precisa ter entre 3 e 60 caracteres e não possuir caracteres especiais'
        )
      }
    },
    hasErrorActive: false
  },
  {
    id: 3,
    label: '*Celular',
    value: '',
    typeStopInteractionSuccess: 'success',
    hasErrorActive: false,
    inputName: 'phone',
    mask: '(99) 99999-9999',
    placeholder: '*Celular',
    applyMaskOnlyThisConditionIsTrue: () => {
      return true
    },
    valid: (value) => {
      const digitValue = value.replace(/\D/g, '') // Remove caracteres = '_' do InputMask
      return digitValue.length === 11
    },
    validation: {
      required: {
        value: true,
        message: 'O campo celular é obrigatório.'
      },
      validate: (value) => {
        const digitValue = value.replace(/\D/g, '') // Remove caracteres = '_' do InputMask
        return digitValue.length === 11 || 'Insira um número válido.'
      }
    }
  }
]

export const inputsLoginCreateAccountEmailStepTwo = [
  {
    id: 1,
    label: 'CPF',
    value: '',
    change: 'Trocar',
    hasErrorActive: false,
    inputName: 'emailOrCpf',
    applyMaskOnlyThisConditionIsTrue: () => {
      return false
    }
  },
  {
    id: 2,
    label: '*Confirmação de e-mail',
    value: '',
    lowerCase: true,
    typeStopInteractionSuccess: 'success',
    inputName: 'email2',
    placeholder: '*Confirmação de e-mail',
    valid: (value) => {
      const emailRegex = /\S+@\S+\.\S+/
      return emailRegex.test(value)
    },
    validation: {
      required: {
        value: true,
        message: 'Confirme seu e-mail.'
      },
      validate: (value) => {
        const emailRegex = /\S+@\S+\.\S+/
        return emailRegex.test(value) || 'Digite um  e-mail válido.'
      }
    },
    onPaste: (e) => {
      e.preventDefault()
      return false
    },
    autoComplete: 'off',
    hasErrorActive: false
  },
  {
    id: 3,
    label: '*Senha',
    pass: true,
    inputName: 'password',
    placeholder: '*Senha',
    validation: {
      required: {
        value: true,
        message: 'O campo senha é obrigatório.'
      },
      validate: (value) => {
        const validations = [
          { name: 'Mínimo uma letra maiúscula*', regex: /[A-Z]/ },
          { name: 'Mínimo uma letra minúscula*', regex: /[a-z]/ },
          { name: 'Mínimo um número*', regex: /[0-9]/ },
          { name: 'Mínimo de 8 caracteres*', regex: /.{8,}/ }
        ]

        for (const validation of validations) {
          if (!validation.regex.test(value)) {
            return 'A senha não atende aos critérios necessários.'
          }
        }

        return true
      }
    },
    value: '',
    helpText: 'Sua senha precisa atender aos seguintes critérios:',
    valid: (value) => {
      const validations = [
        { name: 'Mínimo uma letra maiúscula*', regex: /[A-Z]/ },
        { name: 'Mínimo uma letra minúscula*', regex: /[a-z]/ },
        { name: 'Mínimo um número*', regex: /[0-9]/ },
        { name: 'Mínimo de 8 caracteres*', regex: /.{8,}/ }
      ]

      for (const validation of validations) {
        if (!validation.regex.test(value)) {
          return false
        }
      }

      return true
    },
    validationsPassword: [
      {
        name: 'Mínimo uma letra maiúscula*',
        valid: (str) => /[A-Z]/?.test(str)
      },
      {
        name: 'Mínimo uma letra minúscula *',
        valid: (str) => /[a-z]/?.test(str)
      },
      { name: 'Mínimo um número*', valid: (str) => /[0-9]/?.test(str) },
      { name: 'Mínimo de 8 caracteres*', valid: (str) => str?.length >= 8 }
    ]
  }
]
export const inputsLoginCreateAccountCpfStepTwo = [
  {
    id: 1,
    label: 'E-mail',
    value: '',
    hasErrorActive: false,
    change: 'Trocar',
    typeStopInteraction: 'error',
    typeStopInteractionSuccess: 'success',
    inputName: 'emailOrCpf',
    valid: (value) => {
      const emailRegex = /\S+@\S+\.\S+/
      return validarCPF(value) || emailRegex.test(value)
    },
    validation: {
      required: {
        value: false
      }
    }
  },
  {
    id: 2,
    label: '*E-mail',
    value: '',
    lowerCase: true,
    typeStopInteractionSuccess: 'success',
    inputName: 'email',
    placeholder: '*E-mail',
    valid: (value) => {
      const emailRegex = /\S+@\S+\.\S+/
      return emailRegex.test(value)
    },
    validation: {
      required: {
        value: true,
        message: 'O campo e-mail é obrigatório.'
      },
      validate: (value) => {
        const emailRegex = /\S+@\S+\.\S+/
        return emailRegex.test(value) || 'Digite um  e-mail válido.'
      }
    },
    onPaste: (e) => {
      e.preventDefault()
      return false
    },
    hasErrorActive: false
  },
  {
    id: 3,
    label: '*Confirmação de e-mail',
    value: '',
    lowerCase: true,
    typeStopInteractionSuccess: 'success',
    inputName: 'email2',
    placeholder: '*Confirmação de e-mail',
    valid: (value) => {
      const emailRegex = /\S+@\S+\.\S+/
      return emailRegex.test(value)
    },
    validation: {
      required: {
        value: true,
        message: 'Confirme seu e-mail.'
      },
      validate: (value) => {
        const emailRegex = /\S+@\S+\.\S+/
        return emailRegex.test(value) || 'Digite um  e-mail válido.'
      }
    },
    onPaste: (e) => {
      e.preventDefault()
      return false
    },
    hasErrorActive: false
  },
  {
    id: 4,
    label: '*Senha',
    pass: true,
    inputName: 'password',
    placeholder: '*Senha',
    validation: {
      required: {
        value: true,
        message: 'O campo senha é obrigatório.'
      },
      validate: (value) => {
        const validations = [
          { name: 'Mínimo uma letra maiúscula*', regex: /[A-Z]/ },
          { name: 'Mínimo uma letra minúscula*', regex: /[a-z]/ },
          { name: 'Mínimo um número*', regex: /[0-9]/ },
          { name: 'Mínimo de 8 caracteres*', regex: /.{8,}/ }
        ]

        for (const validation of validations) {
          if (!validation.regex.test(value)) {
            return 'A senha não atende aos critérios necessários.'
          }
        }

        return true
      }
    },
    value: '',
    helpText: 'Sua senha precisa atender aos seguintes critérios:',
    valid: (value) => {
      const validations = [
        { name: 'Mínimo uma letra maiúscula*', regex: /[A-Z]/ },
        { name: 'Mínimo uma letra minúscula*', regex: /[a-z]/ },
        { name: 'Mínimo um número*', regex: /[0-9]/ },
        { name: 'Mínimo de 8 caracteres*', regex: /.{8,}/ }
      ]

      for (const validation of validations) {
        if (!validation.regex.test(value)) {
          return false
        }
      }

      return true
    },
    validationsPassword: [
      {
        name: 'Mínimo uma letra maiúscula*',
        valid: (str) => /[A-Z]/?.test(str)
      },
      {
        name: 'Mínimo uma letra minúscula *',
        valid: (str) => /[a-z]/?.test(str)
      },
      { name: 'Mínimo um número*', valid: (str) => /[0-9]/?.test(str) },
      { name: 'Mínimo de 8 caracteres*', valid: (str) => str?.length >= 8 }
    ]
  }
]
