import styled, { createGlobalStyle } from 'styled-components'
import { ReactSVG } from 'react-svg'
import { normalize } from 'styled-normalize'

import reset from './reset'
import base from './base'

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${reset}
  ${base}
  html {
    background: ${(props) => props.theme?.global?.background?.color};
  }
  small {
    font-size: 12px;
  }
  p {
    line-height: 1.3;
  }
  body {
    font-family: ProximaNova;
    margin: 0;
    padding: 0;
  }
`

export const SReactSVG = styled(ReactSVG)`
  font-size: 0;

  div,
  span {
    font-size: 0;
  }
`

export const IconFooterButton = styled(SReactSVG)`
  width: 15px;
`

export const Container = styled.div`
  overflow-x: hidden;
  height: 100vh;
  position: relative;
  padding-bottom: 70px;
  background-image: url(${(props) => props.theme.images.bg});
`

export const Content = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: content-box;
  padding: 0 10px;
  margin-bottom: 16px;
  justify-content: center;
  @media (min-width: 992px) {
    padding: 0 16px;
  }
`

export const Body = styled.div`
  @media (min-width: 992px) {
    display: flex;
    justify-content: center;
  }
`
